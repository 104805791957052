import animateScrollTo from 'animated-scroll-to'
import { getIn } from 'final-form'

export const scrollToBlock = (refToScroll, menuNode, customScrollElement) => {
  const menuHeight = menuNode ? menuNode.getBoundingClientRect().height : 0

  animateScrollTo(refToScroll.current, {
    speed: 600,
    verticalOffset: -menuHeight,
    elementToScroll: customScrollElement || window,
  })
}

// for Final Form
export const scrollToErrorInput = (inputs, errors) => {
  const firstField = inputs.find(input => {
    return input.name && getIn(errors, input.name)
  })
  const visibleWindow = window.innerHeight / 3 || 0

  animateScrollTo(firstField, {
    speed: 600,
    verticalOffset: -visibleWindow,
  }).then(() => {
    firstField && firstField.focus()
  })
}
