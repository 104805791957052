export const HOME = 'home'
export const DNA_ABOUT = 'dna_about'
export const DNA_RESULTS = 'dna_results'
export const DNA_HOW_IT_WORKS = 'dna_how_it_works'
export const STORIES = 'stories'
export const SCIENCE = 'science'
export const BIOME_ABOUT = 'biome_about'
export const BIOME_NUTRITION = 'biome_nutrition'
export const BIOME_RESULTS = 'biome_results'
export const BIOME_HOW_IT_WORKS = 'biome_how_it_works'
export const WGS_ABOUT = 'wgs_about'
export const WGS_RESULTS = 'wgs_results'
export const WGS_HOW_IT_WORKS = 'wgs_how_it_works'
export const ABOUT = 'about'
export const CONTACTS = 'contacts'
export const FAQ = 'faq'
export const PRIVACY_POLICY = 'privacy'
export const TERMS_AND_CONDITIONS = 'terms'
export const TERMS_AND_CONDITIONS2017 = 'terms2017'
export const TERMS_AND_CONDITIONS2018 = 'terms2018'
export const TERMS_AND_CONDITIONS2019_EARLY = 'terms2019early'
export const TERMS_AND_CONDITIONS_B2B = 'terms_b2b'
export const TERMS_AND_CONDITIONS_GC = 'terms_gc'
export const COOKIE_POLICY = 'cookie'
export const CART = 'cart'
export const ESSENTIAL_POINTS = 'essential_points'

// Publications
export const PUBLICATIONS_GENERAL = 'publications'
export const PUBLICATIONS_BIOME = 'publications_biome'
export const PUBLICATIONS_DNA = 'publications_dna'
export const PUBLICATIONS_WGS = 'publications_wgs'

// Traits list
export const TRAITS_LIST = 'traits_list'

// Stories
export const STORIES_HOME = 'stories_home'
export const STORIES_BIOME = 'stories_biome'
export const STORIES_DNA = 'stories_dna'
export const STORIES_WGS = 'stories_wgs'
export const STORIES_ALL = 'stories_all'

// other
export const CAROUSEL = 'carousel'

export default [
  HOME,
  DNA_ABOUT,
  DNA_RESULTS,
  DNA_HOW_IT_WORKS,
  WGS_ABOUT,
  WGS_RESULTS,
  WGS_HOW_IT_WORKS,
  STORIES,
  SCIENCE,
  BIOME_ABOUT,
  BIOME_NUTRITION,
  BIOME_RESULTS,
  BIOME_HOW_IT_WORKS,
  ABOUT,
  CONTACTS,
  FAQ,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
  TERMS_AND_CONDITIONS2017,
  TERMS_AND_CONDITIONS2018,
  TERMS_AND_CONDITIONS2019_EARLY,
  TERMS_AND_CONDITIONS_B2B,
  COOKIE_POLICY,
  CART,

  PUBLICATIONS_GENERAL,
  PUBLICATIONS_BIOME,
  PUBLICATIONS_DNA,
  PUBLICATIONS_WGS,

  TRAITS_LIST,

  STORIES_HOME,
  STORIES_BIOME,
  STORIES_DNA,
  STORIES_WGS,
  STORIES_ALL,

  CAROUSEL,
]
