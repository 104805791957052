import PropTypes from 'prop-types'
import React from 'react'
import { ThemeContext } from 'styled-components'

import Icon from 'components/Icon'
import Link from 'components/Link'

import {
  Box,
  Substrate,
  BackgroundCircle,
  BlackOutCircle,
  ButtonInner,
} from './styles'

const components = {
  button: 'button',
  a: Link,
  Link: Link,
}

const ButtonCircleSt = ({
  children,

  component,
  onClick,
  linkProps,

  size,
  bg,
  blackout,
  icon,

  isHoverable,
  isDisabled,
  isHighlight,
}) => {
  const { buttons, colors } = React.useContext(ThemeContext)
  const dr = React.useMemo(() => (size === 'small' ? 0.5 : 1), [size])
  const sizeValue = React.useMemo(() => buttons[size], [buttons, size])

  const boxAs = components[component]

  const SubstrateMemo = React.useCallback(
    () => (
      <Substrate
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox={`0 0 ${sizeValue} ${sizeValue}`}
      >
        <BackgroundCircle
          cx={sizeValue / 2}
          cy={sizeValue / 2}
          r={sizeValue / 2 - dr}
          {...{
            bg,
            isHighlight,
          }}
          bg={bg}
        />
        <BlackOutCircle
          cx={sizeValue / 2}
          cy={sizeValue / 2}
          r={sizeValue / 2 - dr}
          {...{
            blackout,
            isHighlight,
          }}
        />
      </Substrate>
    ),
    [sizeValue, bg, blackout, dr, isHighlight],
  )

  const textColor = React.useMemo(() => {
    switch (true) {
      case bg === 'transparent':
      case bg === 'white':
        return colors.bodyColor
      default:
        return colors.white
    }
  }, [colors, bg])
  const textHoverColor = React.useMemo(() => {
    switch (true) {
      case bg === 'white' && blackout === 'transparent':
        return colors.bodyColor
      default:
        return colors.white
    }
  }, [colors, bg, blackout])
  const content = React.useMemo(
    () =>
      icon ? <Icon id={icon.id} color={icon?.color || textColor} /> : children,
    [icon, children, textColor],
  )

  return (
    <Box
      {...{
        as: boxAs,
        component,
        onClick,
        isHoverable,
        isDisabled,
        ...linkProps,
      }}
    >
      <SubstrateMemo />
      <ButtonInner
        {...{
          size,
          withIcon: !!icon,
          width: sizeValue,
          height: sizeValue,
          color: textColor,
          hoverColor: icon?.colorHover || textHoverColor,
          isDisabled,
          isHighlight,
        }}
      >
        {content}
      </ButtonInner>
    </Box>
  )
}

ButtonCircleSt.propTypes = {
  /** Kind of wrapper component */
  component: PropTypes.oneOf(['a', 'button', 'Link']),

  /** Color for background tag */
  bg: PropTypes.string,

  /** Color for blackout tag */
  blackout: PropTypes.string,

  /** Title of button */
  children: PropTypes.node,

  /** Icon parameters — [look Icon component](#!/Icon) */
  icon: PropTypes.shape({
    id: PropTypes.string,
    color: PropTypes.string,
    colorHover: PropTypes.string,
  }),

  /** Props for Link component */
  linkProps: PropTypes.shape({
    to: PropTypes.string,
  }),

  /** Flag to control disable state */
  isDisabled: PropTypes.bool,

  /** Flag to control possibility of hover action */
  isHoverable: PropTypes.bool,

  /** Size of component */
  size: PropTypes.oneOf(['xs', 'small', 'newSmall', 'base', 'large']),

  /** Flag to control highlight button */
  isHighlight: PropTypes.bool,

  /** Click callback for buttons */
  onClick: PropTypes.func,
}

ButtonCircleSt.defaultProps = {
  component: 'Link',
  children: '',
  size: 'base',
  bg: 'transparent',
  blackout: 'lilacDark',
  isDisabled: false,
  isHoverable: true,
  isHighlight: false,
}

export default ButtonCircleSt
