import CSSPlugin from 'gsapAlias/CSSPlugin'
import TimelineLite from 'gsapAlias/TimelineLite'
import { grass } from 'styles/theme/colors'

// eslint-disable-next-line no-unused-vars
const plugins = [CSSPlugin]

const BASE = 2
const FIRST_PART = (BASE / 3) * 2
const SECOND_PART = BASE / 3

const FADE_OUT = FIRST_PART / 3
const DECREASING = FIRST_PART / 3
const SUCCESS_COLOR = FADE_OUT

const INCREASING = SECOND_PART
const FADE_IN_TEXT = SECOND_PART / 3
const FADE_IN_OVERLAP = SECOND_PART / 3

const TEXT_DY = 15

/*
 * Sequence of animation
 * 1. Decrease width, hide text and colorize background to success color
 * 2. Show and hide success status icon and colorize button
 * 3. Increase width of button and show text
 */

export const decreaseWidthToLeftAndHideText = ({
  wrapper,
  bg,
  text,
  blackout,
}) => {
  const timeline = new TimelineLite()
  const { height, width } = wrapper?.getBoundingClientRect()

  timeline
    .set(text, {
      y: 0,
      opacity: 1,
    })
    .set(bg, {
      scaleX: 1,
      scaleY: 1,
      width: width,
    })
    .set(blackout, {
      opacity: 0,
      scaleX: 1,
      scaleY: 1,
    })
    .to(text, FADE_OUT, {
      y: TEXT_DY,
      opacity: 0,
    })
    .to(bg, DECREASING, {
      width: height,
    })
    .to(bg, SUCCESS_COLOR, {
      backgroundColor: grass,
    })

  return timeline
}

export const decreaseWidthToRightAndHideText = ({
  wrapper,
  bg,
  text,
  blackout,
}) => {
  const timeline = new TimelineLite()
  const { height, width } = wrapper.getBoundingClientRect()

  timeline
    .set(wrapper, {
      width,
    })
    .set(text, {
      y: 0,
      opacity: 1,
    })
    .set(bg, {
      x: 0,
      scaleX: 1,
      scaleY: 1,
      width,
    })
    .set(blackout, {
      opacity: 0,
      scaleX: 1,
      scaleY: 1,
    })
    .to(text, FADE_OUT, {
      y: TEXT_DY,
      opacity: 0,
    })
    .to(bg, DECREASING, {
      x: width - height,
      width: height,
    })
    .to(bg, SUCCESS_COLOR, {
      backgroundColor: grass,
    })
    .set(text, {
      marginRight: 0,
    })

  return timeline
}

export const showAndHideSuccessIcon = ({ wrapper, text }) => {
  const timeline = new TimelineLite()
  const { height } = wrapper.getBoundingClientRect()

  timeline
    .set(text, {
      opacity: 0,
      y: 0,
      width: height,
    })
    .to(text, FADE_OUT, {
      opacity: 1,
    })
    .to(text, FADE_OUT, {})
    .to(text, FADE_OUT, {
      opacity: 0,
    })
    .set(text, {
      width: 'auto',
    })

  return timeline
}

export const increaseWidthToLeftAndShowText = ({ wrapper, bg, text }) => {
  const timeline = new TimelineLite()
  const { height } = wrapper.getBoundingClientRect()

  timeline
    .set(text, {
      y: TEXT_DY,
      opacity: 0,
    })
    .set(bg, {
      width: height,
    })
    .to(bg, INCREASING, {
      width: '100%',
    })
    .to(
      text,
      FADE_IN_TEXT,
      {
        y: 0,
        opacity: 1,
      },
      `-=${FADE_IN_OVERLAP}`,
    )

  return timeline
}

export const increaseWidthToRightAndShowText = ({ wrapper, bg, text }) => {
  const timeline = new TimelineLite()
  const { height, width } = wrapper.getBoundingClientRect()

  timeline
    .set(text, {
      y: TEXT_DY,
      opacity: 0,
    })
    .set(bg, {
      x: width - height,
      width: height,
    })
    .to(bg, INCREASING, {
      x: 0,
      width: width,
    })
    .to(
      text,
      FADE_IN_TEXT,
      {
        y: 0,
        opacity: 1,
      },
      `-=${FADE_IN_OVERLAP}`,
    )

  return timeline
}

export const decreaseWidthToCenterAndHideText = ({
  wrapper,
  bg,
  blackout,
  text,
}) => {
  const timeline = new TimelineLite()
  const { height, width } = wrapper.getBoundingClientRect()
  const btnCenter = (width - height) / 2

  timeline
    .set(wrapper, {
      width,
    })
    .set(text, {
      y: 0,
      opacity: 1,
    })
    .set(bg, {
      x: 0,
      scaleX: 1,
      scaleY: 1,
      width: width,
    })
    .set(blackout, {
      opacity: 0,
      scaleX: 1,
      scaleY: 1,
    })
    .to(text, FADE_OUT, {
      y: TEXT_DY,
      opacity: 0,
    })
    .to(bg, DECREASING, {
      x: btnCenter,
      width: height,
    })
    .to(bg, SUCCESS_COLOR, {
      backgroundColor: grass,
    })

  return timeline
}

export const increaseWidthToCenterAndShowText = ({ wrapper, bg, text }) => {
  const timeline = new TimelineLite()
  const { height, width } = wrapper.getBoundingClientRect()
  const btnCenter = (width - height) / 2

  timeline
    .set(text, {
      y: TEXT_DY,
      opacity: 0,
    })
    .set(bg, {
      x: btnCenter,
      width: height,
    })
    .to(bg, INCREASING, {
      x: 0,
      width: width,
    })
    .to(
      text,
      FADE_IN_TEXT,
      {
        y: 0,
        opacity: 1,
      },
      `-=${FADE_IN_OVERLAP}`,
    )

  return timeline
}
