import { detect } from 'detect-browser'
import MobileDetect from 'mobile-detect'

import {
  breakpoints,
  mobile,
  tablet,
  desktop,
  scaling,
} from 'constants/breakpoint'

export const isBrowser = () => {
  const name = process.browser ? detect()?.name : null

  if (!name) {
    return {
      chrome: null,
      firefox: null,
      edge: null,
      safari: null,
    }
  }

  return {
    chrome: name === 'chrome',
    firefox: name === 'firefox',
    edge: name === 'edge',
    safari: name === 'safari',
  }
}

// Legacy method to determine is it Android or IOS device
// export const isAndroidOrIOS = () => {
//   const md = process.browser
//     ? new MobileDetect(window.navigator.userAgent)
//     : null
//
//   if (!md) {
//     return null
//   }
//
//   const isIPhone = md.is('iPhone')
//   const isAndroidMobile = md.phone() && md.os() === 'AndroidOS'
//
//   return !!(isIPhone || isAndroidMobile)
// }

export const restrictUIX = () => {
  let noAnimation = false
  let noWebGL = true

  if (process.browser) {
    const modernizr = require('./modernizr')
    const md = new MobileDetect(window.navigator.userAgent)

    if (!window.Modernizr) {
      modernizr()
    }

    if (md && Modernizr && Modernizr.touchevents) {
      if (md.phone()) {
        noAnimation = true
      }
    }

    const canvas = document.createElement('canvas')
    const gl =
      canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
    // Report the result.

    if (gl && gl instanceof WebGLRenderingContext) {
      noWebGL = false
    }
  }

  return {
    noAnimation,
    noWebGL,
  }
}

// Legacy method to determine whether it real tablet or not
// export const isTablet = () => {
//   const md = process.browser
//     ? new MobileDetect(window.navigator.userAgent)
//     : null
//
//   if (!md) {
//     return null
//   }
//
//   /*
//    * Hack to detect new Safari on Ipad (that looks like the same as MacOs's Safari)
//    */
//   const isItNewIpad =
//     window.navigator.userAgent.includes('Safari') &&
//     'standalone' in window.navigator
//
//   return !!md.tablet() || isItNewIpad
// }

// Legacy method to determine is it Retina
// export const isRetina = () => {
//   if (process.browser && window.matchMedia) {
//     const mq = window.matchMedia(
//       'only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)',
//     ) // eslint-disable-line
//
//     return (mq && mq.matches) || window.devicePixelRatio > 1
//   }
//
//   return null
// }

// check_webp_feature:
//   'feature' can be one of 'lossy', 'lossless', 'alpha' or 'animation'.
//   'callback(feature, result)' will be passed back the detection result (in an asynchronous way!)
function check_webp_feature(feature) {
  return new Promise(res => {
    const kTestImages = {
      lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
      lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
      alpha:
        'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
      animation:
        'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
    }

    const img = new Image()
    img.onload = function() {
      res(img.width > 0 && img.height > 0)
    }
    img.onerror = function() {
      res(false)
    }
    img.src = 'data:image/webp;base64,' + kTestImages[feature]
  })
}

export const isWebpSupport = async () => {
  if (process.browser) {
    return await check_webp_feature('alpha')
  }

  return false
}

export const checkBreakpoints = resetBreakpoints => {
  let isMobile = false
  let isTablet = false
  let isDesktop = false
  let isScaling = false
  let breakpoint = ''

  const width = process.browser && !resetBreakpoints ? window.innerWidth : null

  if (width) {
    if (width < breakpoints.tablet) {
      isMobile = true
      breakpoint = mobile
    } else if (width >= breakpoints.tablet && width < breakpoints.desktop) {
      isTablet = true
      breakpoint = tablet
    } else if (width >= breakpoints.desktop && width < breakpoints.scaling) {
      isDesktop = true
      breakpoint = desktop
    } else {
      isScaling = true
      breakpoint = scaling
    }
  }

  return {
    mobile: isMobile,
    tablet: isTablet,
    desktop: isDesktop,
    scaling: isScaling,
    breakpoint,
  }
}
