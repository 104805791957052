import PropTypes from 'prop-types'
import React from 'react'

import ButtonRoundedBaseSt from 'components/ButtonRoundedBaseSt'

/**
 * Dummy component of Button with rounded corners
 * @param {object} props — properties of button
 * @return {function} Function — ButtonRounded
 */
const ButtonRoundedSt = props => {
  return <ButtonRoundedBaseSt {...props} />
}

ButtonRoundedSt.propTypes = {
  /** Icon inside button */
  icon: PropTypes.node,

  /** False or type of appearance animation */
  animation: PropTypes.oneOf([false, 'left', 'center']),

  /** Customize delay for appearance animation */
  animationDelay: PropTypes.number,

  /** Kind of wrapper component */
  as: PropTypes.oneOf(['a', 'button', 'Link']),

  /** String for client-routing for Link component */
  asPath: PropTypes.string,

  /** Styles for background tag */
  bgStyles: PropTypes.array,

  /** Styles for blackout tag */
  blackoutStyles: PropTypes.array,

  /** Title of button */
  children: PropTypes.node,

  /** Flag to control disable state */
  isDisabled: PropTypes.bool,

  /** Size of component */
  /** fit - not determined button size, manipulated by content */
  size: PropTypes.oneOf(['fit', 'slim', 'base', 'medium', 'large']),

  /** Styles for text tag */
  textStyles: PropTypes.array,

  /** Use this prop for 'Link' and 'a'  */
  to: PropTypes.string,

  /** Flag to set 2px margin-top on mobile */
  withTopMarginOnMobile: PropTypes.bool,
}

ButtonRoundedSt.defaultProps = {
  children: '',
  as: 'a',
  to: '',
  size: 'base',
  animation: false,
  animationDelay: 0,
  isDisabled: false,
  withTopMarginOnMobile: false,
}

export default ButtonRoundedSt
