import React from 'react'

export default () => {
  const [swiper, updateSwiper] = React.useState(null)
  const [activeIndex, setActiveIndex] = React.useState(1)

  const onGoLeft = React.useCallback(() => {
    if (swiper) {
      swiper.slidePrev()
    }
  }, [swiper])

  const onGoRight = React.useCallback(() => {
    if (swiper) {
      swiper.slideNext()
    }
  }, [swiper])

  const slideTo = React.useCallback(
    (index, speed, cb) => {
      if (swiper) {
        swiper.slideTo(index, speed, cb)
      }
    },
    [swiper],
  )

  const handleSlideChange = React.useCallback(() => {
    if (swiper) {
      const { realIndex } = swiper
      setActiveIndex(realIndex + 1)
    }
  }, [swiper])

  React.useEffect(() => {
    if (swiper) {
      swiper.on('slideChange', handleSlideChange)
    }

    return () => {
      if (swiper) {
        swiper.off('slideChange', handleSlideChange)
        swiper.destroy()
      }
    }
  }, [swiper, handleSlideChange])

  return {
    swiper,
    updateSwiper,
    activeIndex,
    slideTo,
    onGoLeft,
    onGoRight,
  }
}
