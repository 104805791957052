const mobile = 'mobile'
const tablet = 'tablet'
const desktop = 'desktop'
const scaling = 'scaling'

const breakpoints = {
  [tablet]: 768,
  [desktop]: 1200,
  [scaling]: 1600,
}

module.exports = {
  mobile,
  tablet,
  desktop,
  scaling,
  breakpoints,
}
