import { css } from 'styled-components'

export const japaneseBehaviour = css`
  :lang(ja) {
    word-break: keep-all;
  }
`

export const h1 = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.h1};
  line-height: ${p => p.theme.typography.h1Lh};
  margin-bottom: ${p => p.theme.typography.h1MarginBottom};
  font-weight: 400;
`

export const h1Md = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.h1Md};
  line-height: ${p => p.theme.typography.h1LhMd};
  margin-bottom: ${p => p.theme.typography.h1MarginBottomMd};
  font-weight: 400;
`

export const h1MdSmall = css`
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.h1MdSmall};
  line-height: ${p => p.theme.typography.h1LhMd};
  margin-bottom: ${p => p.theme.typography.h1MarginBottomMdSmall};
  font-weight: 400;
`

export const h2 = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.h2};
  line-height: ${p => p.theme.typography.h2Lh};
  margin-bottom: ${p => p.theme.typography.h2MarginBottom};
  font-weight: 400;
`

export const h2Md = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.h2Md};
  line-height: ${p => p.theme.typography.h2LhMd};
  margin-bottom: ${p => p.theme.typography.h2MarginBottomMd};
  font-weight: 400;
`

export const h3 = css`
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.h3};
  line-height: ${p => p.theme.typography.h3Lh};
  margin-bottom: ${p => p.theme.typography.h3MarginBottom};
  font-weight: 400;
`

export const h3Md = css`
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.h3Md};
  line-height: ${p => p.theme.typography.h3Lh};
  margin-bottom: ${p => p.theme.typography.h3MarginBottomMd};
  font-weight: 400;
`

export const h4 = css`
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.h4};
  line-height: ${p => p.theme.typography.h4Lh};
  margin-bottom: ${p => p.theme.typography.h4MarginBottom};
  font-weight: 400;
`

export const h4Md = css`
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.h4};
  line-height: ${p => p.theme.typography.h4Lh};
  margin-bottom: ${p => p.theme.typography.h4MarginBottomMd};
  font-weight: 400;
`

export const cartBase = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.baseFont};
  font-size: ${p => p.theme.typography.cartFontSize};
  line-height: ${p => p.theme.typography.cartFontLh};
  font-weight: 400;
`

export const cartItemTitle = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.baseFont};
  font-size: ${p => p.theme.typography.cartItemFontSize};
  line-height: ${p => p.theme.typography.cartItemFontLh};
  font-weight: 400;
`

export const cartItemTitleMd = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.baseFont};
  font-size: ${p => p.theme.typography.cartItemFontSizeMd};
  line-height: ${p => p.theme.typography.cartItemFontLhMd};
  font-weight: 400;
`

export const faqH2 = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.baseFont};
  font-size: ${p => p.theme.typography.faqH2};
  line-height: ${p => p.theme.typography.faqH2Lh};
  font-weight: 400;
`

export const faqH2Md = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.faqH2Md};
  line-height: ${p => p.theme.typography.faqH2MdLh};
  font-weight: 400;
`

export const footerBase = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.baseFont};
  font-size: ${p => p.theme.typography.footerFontSize};
  line-height: ${p => p.theme.typography.baseLh};
  font-weight: 400;
`

export const footerBaseMd = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.baseFont};
  font-size: ${p => p.theme.typography.footerFontSizeMd};
  line-height: ${p => p.theme.typography.baseLh};
  font-weight: 400;
`

export const textBaseXs = css`
  font-family: ${p => p.theme.typography.baseFont};
  font-size: ${p => p.theme.typography.fontSizeXs};
  line-height: ${p => p.theme.typography.baseLhXs};
  font-weight: 400;
`

export const textBase = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.baseFont};
  font-size: ${p => p.theme.typography.fontSize};
  line-height: ${p => p.theme.typography.baseLh};
  font-weight: 400;
`

export const textBaseMd = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.baseFont};
  font-size: ${p => p.theme.typography.fontSizeMd};
  line-height: ${p => p.theme.typography.baseLh};
  font-weight: 400;
`

export const textBold = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.fontSize};
  line-height: ${p => p.theme.typography.baseLh};
  font-weight: 400;
`

export const textBoldMd = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.fontSizeMd};
  line-height: ${p => p.theme.typography.baseLh};
  font-weight: 400;
`

export const priceSmall = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.baseFont};
  font-size: ${p => p.theme.typography.priceSmall};
  line-height: ${p => p.theme.typography.priceSmallLh};
  font-weight: 400;
`

export const priceBaseBold = css`
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.priceBaseBold};
  line-height: ${p => p.theme.typography.priceBaseBoldLh};
  font-weight: 400;
`

export const priceMedium = css`
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.fontSize};
  line-height: ${p => p.theme.typography.priceMediumLh};
  font-weight: 400;
`

export const priceMediumMd = css`
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.priceMedium};
  line-height: ${p => p.theme.typography.priceMediumLh};
  font-weight: 400;
`

export const priceBig = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.priceBig};
  line-height: ${p => p.theme.typography.priceBigLh};
  font-weight: 400;
`

export const priceBigMd = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.priceBigMd};
  line-height: ${p => p.theme.typography.priceBigLhMd};
  font-weight: 400;
`

export const textMedium = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.baseFont};
  font-size: ${p => p.theme.typography.textMedium};
  line-height: ${p => p.theme.typography.textMediumLh};
  font-weight: 400;
`

export const textCartSubtitle = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.fontSize};
  line-height: ${p => p.theme.typography.textCartSubtitleLh};
  font-weight: 400;
`

export const textCartSubtitleMd = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.textCartSubtitle};
  line-height: ${p => p.theme.typography.textCartSubtitleLh};
  font-weight: 400;
`

export const textDisclaimer = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.baseFont};
  font-size: ${p => p.theme.typography.textDisclaimer};
  line-height: ${p => p.theme.typography.textDisclaimerLh};
  font-weight: 400;
`

export const textDisclaimerMd = css`
  ${japaneseBehaviour};
  font-family: ${p => p.theme.typography.baseFont};
  font-size: ${p => p.theme.typography.textDisclaimerMd};
  line-height: ${p => p.theme.typography.textDisclaimerMdLh};
  font-weight: 400;
`
