/**
 * Created by Edward on 15/05/2017.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'

import { Text } from 'styles/components/typography'

import './SliderTextCounter.scss'

const SliderTextCounter = ({ curIndex, descriptions, className }) => {
  return (
    <div className={`st-counter__wrapper ${className}`}>
      <TransitionGroup component={null}>
        <Transition timeout={300} key={`counter.${curIndex}`}>
          {status => (
            <div className={`st-counter__index st-counter__index--${status}`}>
              <Text
                as="span"
                dangerouslySetInnerHTML={{ __html: descriptions[curIndex - 1] }}
              />
            </div>
          )}
        </Transition>
      </TransitionGroup>
    </div>
  )
}

SliderTextCounter.propTypes = {
  descriptions: PropTypes.array.isRequired,
  className: PropTypes.string,
  curIndex: PropTypes.number,
}

SliderTextCounter.defaultProps = {
  curIndex: 0,
  className: '',
}

export default SliderTextCounter
