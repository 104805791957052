import { em, math } from 'polished'
import styled, { css } from 'styled-components'

import { unstyleButton } from 'styles/mixins'

const textCss = css`
  position: relative;
  font-family: ${p => p.theme.typography.baseFont};
  text-align: center;
  height: 100%;
  -webkit-backface-visibility: hidden;
  color: ${p => p.theme.colors.white};
`

export const ContentBox = styled.div`
  ${textCss};
  display: block;
  margin: 0 auto;
  padding: 0 ${em(16.5)};

  ${p =>
    p.textStyles &&
    css`
      ${p.textStyles};
    `}
`

export const TextBox = styled.div`
  ${textCss};
  display: flex;
  align-items: center;
  padding: 0 ${p => p.theme.buttons.buttonRoundedTextIndent};
  justify-content: center;
  transition: color ${p => p.theme.animation.timeBtns}
    ${p => p.theme.animation.cubicBezier};
  white-space: nowrap;
  user-select: none;

  ${p =>
    p.textStyles &&
    css`
      ${p.textStyles};

      ${IconBox} svg {
        fill: currentColor !important;
        stroke: currentColor !important;
      }
    `}
`

export const SuccessText = styled.div`
  color: ${p => p.theme.colors.muted};
  display: block;
  margin-left: ${em(10)};

  ${p => p.theme.mq.upMd} {
    display: inline-block;
  }

  ${p => p.theme.mq.upLg} {
    display: block;
    margin-left: 0;
  }
`

export const IconBox = styled.div`
  position: relative;
  margin: 0 auto;

  &--right {
    display: block;
    margin-right: 0;
  }
`

export const background = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition-duration: ${p => p.theme.animation.timeBtns};
  transition-timing-function: ${p => p.theme.animation.cubicBezier};
`

export const Bg = styled.div`
  ${background};
  border-radius: ${p => p.theme.buttons.hActionBtn};
  background-color: var(--color-V50);
  transition-property: opacity, transform, border-color, background-color;

  ${p =>
    p.bgStyles &&
    css`
      ${p.bgStyles};
    `}
`

export const Blackout = styled.div`
  ${background};
  border-radius: ${p => p.theme.buttons.hActionBtn};
  background-color: ${p => p.theme.colors.blackOp07};
  opacity: 0;
  transition-property: opacity, transform;

  ${p =>
    p.blackoutStyles &&
    css`
      ${p.blackoutStyles};
    `}
`

export const Wrapper = styled.div`
  ${unstyleButton};
  display: inline-block;
  position: relative;
  background: transparent;
  border: none;
  color: ${p => p.theme.colors.bodyColor};
  cursor: pointer;
  padding: 0;
  text-align: center;
  overflow: visible;

  &:focus {
    outline: 0;
  }

  ${p =>
    p.size === 'base' &&
    css`
      height: ${p => p.theme.buttons.hActionBtn};
      line-height: ${p => p.theme.buttons.hActionBtn};

      ${IconBox} {
        width: ${p => p.theme.buttons.hActionBtn};
        height: ${p => p.theme.buttons.hActionBtn};
        padding: ${p => p.theme.buttons.buttonInnerIndentBase} 0;
      }

      ${TextBox} {
        top: -${em(2)};

        ${IconBox} {
          margin-left: -${p => math(`${p.theme.buttons.buttonInnerIndentBase} * 1.5`)};
        }
      }
    `}

  ${p =>
    p.size === 'slim' &&
    css`
      height: ${p => p.theme.buttons.hActionBtnSlim};

      ${IconBox} {
        width: ${p => p.theme.buttons.hActionBtnSlim};
        height: ${p => p.theme.buttons.hActionBtnSlim};
        padding: ${p => p.theme.buttons.buttonInnerIndentSmall} 0;
      }

      ${TextBox} {
        align-items: flex-start;

        ${IconBox} {
          margin-left: -${p => math(`${p.theme.buttons.buttonInnerIndentSmall} * 2`)};
        }
      }
    `}

  ${p =>
    p.size === 'medium' &&
    css`
      height: ${p => p.theme.buttons.hActionBtnMedium};
      line-height: ${p => p.theme.buttons.hActionBtnMedium};

      ${IconBox} {
        width: ${p => p.theme.buttons.hActionBtnMedium};
        height: ${p => p.theme.buttons.hActionBtnMedium};
        padding: ${p => p.theme.buttons.buttonInnerIndentMedium} 0;
      }

      ${TextBox} {
        top: -${em(2)};

        ${IconBox} {
          margin-left: -${p => math(`${p.theme.buttons.buttonInnerIndentMedium} * 1.2`)};
        }
      }
    `}

  ${p =>
    p.size === 'large' &&
    css`
      height: ${p => p.theme.buttons.hActionBtnLarge};
      line-height: ${p => p.theme.buttons.hActionBtnLarge};

      ${IconBox} {
        width: ${p => p.theme.buttons.hActionBtnLarge};
        height: ${p => p.theme.buttons.hActionBtnLarge};
        padding: ${p => p.theme.buttons.buttonInnerIndentLarge} 0;
      }

      ${TextBox} {
        top: -${em(2)};

        ${IconBox} {
          margin-left: -${p => math(`${p.theme.buttons.buttonInnerIndentLarge} * 1.2`)};
        }
      }
    `}

  ${p =>
    p.withTopMarginOnMobile &&
    css`
      margin-top: 2px;

      ${p => p.theme.mq.upMd} {
        margin-top: 0;
      }
    `}

  ${p =>
    p.notHoverable &&
    css`
      pointer-events: none;
    `}

  ${p =>
    p.isAnimating &&
    css`
      ${Bg} {
        transition: none;
      }
    `}

  ${p =>
    p.isAppearance &&
    css`
      ${Bg} {
        width: 0;
        transform: scale(0);
      }

      ${Blackout} {
        visibility: hidden;
      }

      ${TextBox} {
        opacity: 0;
      }
    `}

  ${p =>
    p.isDisabled &&
    css`
      pointer-events: none;

      ${Bg} {
        background: ${p => p.theme.colors.blackOp07};
      }

      ${TextBox} {
        color: ${p => p.theme.colors.white};

        svg {
          fill: ${p => p.theme.colors.white} !important;
          stroke: ${p => p.theme.colors.white} !important;
        }
      }
    `}


  &:active {
    ${Bg},
    ${Blackout} {
      transform: scale(1);
    }
  }

  ${p => p.theme.mq.hover} {
    &:hover {
      ${Bg},
      ${Blackout} {
        transform: scale(${p =>
          p.theme.buttons.buttonRoundedGrowingCoef}) translateZ(0);
      }

      ${Blackout} {
        opacity: 1;
      }
    }
  }
`
