/*
 * We are loading Sentry from node_modules not from cdn
 * because of in Russia "RKN" sometimes blocks that cdn
 */
import * as SentryBrowser from '@sentry/browser'

const { LOGGING, SENTRY_TOKEN, SENTRY_ID } = process.env.server

class Sentry {
  constructor() {
    if (LOGGING) {
      SentryBrowser.init({
        release: `ssr-abg@${process.env.npm_package_version}`,
        dsn: `https://${SENTRY_TOKEN}@sentry.io/${SENTRY_ID}`,
        environment: process.env.SERVER,
        beforeBreadcrumb(breadcrumb) {
          // filter analytics requests
          if (
            breadcrumb.category === 'xhr' &&
            breadcrumb.data?.url &&
            (breadcrumb.data.url.includes('https://mc.yandex.ru') ||
              breadcrumb.data.url.includes('https://mc.webvisor.org') ||
              breadcrumb.data.url.includes('https://stats.g.doubleclick.net') ||
              breadcrumb.data.url.includes('https://api.amplitude.com'))
          ) {
            return null
          }

          return breadcrumb
        },
      })
    }
  }

  captureException(error) {
    if (LOGGING) {
      SentryBrowser.captureException(error)
    }
  }

  captureMessage(message) {
    if (LOGGING) {
      SentryBrowser.captureMessage(message)
    }
  }

  captureErrorWithScope(error, errorInfo) {
    this.__addExtra(errorInfo)
    this.captureException(error)
  }

  captureMessageWithScope(message, extra) {
    this.__addExtra(extra)
    this.captureMessage(message)
  }

  addBreadcrumb(params) {
    if (LOGGING) {
      SentryBrowser.addBreadcrumb(params)
    }
  }

  setPage(page) {
    if (LOGGING) {
      SentryBrowser.configureScope(function(scope) {
        scope.setTag('page', page)
      })
    }
  }

  setEmailOrder(email) {
    if (LOGGING) {
      SentryBrowser.configureScope(function(scope) {
        scope.setTag('email_order', email)
      })
    }
  }

  setGA(ga) {
    if (LOGGING) {
      SentryBrowser.setUser({ id: ga })
    }
  }

  setSmartlook(url) {
    if (LOGGING) {
      SentryBrowser.setUser({ smartlook: url })
    }
  }

  __addExtra(obj) {
    if (LOGGING) {
      SentryBrowser.configureScope(scope => {
        Object.keys(obj).forEach(key => {
          scope.setExtra(key, obj[key])
        })
      })
    }
  }
}

export default new Sentry()
