import PropTypes from 'prop-types'
import React from 'react'

import Link from 'components/Link'
import { Text } from 'styles/components/typography'

import {
  showButtonFromLeft,
  showButtonFromCenter,
  showButtonFromRight,
} from './animations'
import {
  Bg,
  Blackout,
  ContentBox,
  IconBox,
  SuccessText,
  TextBox,
  Wrapper,
} from './styles'

const [ANIMATION_LEFT, ANIMATION_CENTER, ANIMATION_RIGHT] = [
  'left',
  'center',
  'right',
]

/**
 * Component of Button with rounded corners
 * @return {Component} Component — ButtonRoundedBase
 */
const ButtonRoundedBaseSt = ({
  children,
  as,
  size,
  isDisabled,
  successText,
  icon,
  bgStyles,
  blackoutStyles,
  textStyles,
  animation,
  animationDelay,
  animTimeline,
  animTimelineResetStyles,
  animTimelineCb,
  notHoverable,
  withTopMarginOnMobile,
  to,
  asPath,
  ...wrapperProps
}) => {
  const bgRef = React.useRef(null)
  const blackoutRef = React.useRef(null)
  const textRef = React.useRef(null)
  const wrapperRef = React.useRef(null)

  const timerDelay = React.useRef(null)
  const btnAppeareTween = React.useRef(null)
  const outerTween = React.useRef(null)

  const [isAppearing, setIsAppearing] = React.useState(false)
  // need this flag cause appearance animation delay
  const [isAppeared, setIsAppeared] = React.useState(!animation)
  const [isOuterAnimating, setIsOuterAnimating] = React.useState(!!animTimeline)

  React.useEffect(() => {
    if (animTimeline) setIsOuterAnimating(true)
  }, [animTimeline])

  React.useEffect(() => {
    if (animation && !timerDelay.current) {
      timerDelay.current = setTimeout(() => {
        setIsAppearing(true)
      }, animationDelay)
    }
  }, [animation, animationDelay])

  /**
   * Component of Button with rounded corners
   * @param {string} animationType - Type of appearance animation
   * @return {Component} Component — TimeLine for appearance animation
   */
  const getAnimationTimeline = React.useCallback(() => {
    const wrapper = wrapperRef.current
    const bg = bgRef.current
    const text = textRef.current

    switch (animation) {
      case ANIMATION_LEFT:
        return showButtonFromLeft(wrapper, bg, text)
      case ANIMATION_CENTER:
        return showButtonFromCenter(wrapper, bg, text)
      case ANIMATION_RIGHT:
        return showButtonFromRight(wrapper, bg, text)
      default:
        return null
    }
  }, [animation])

  React.useEffect(() => {
    if (isAppearing) {
      btnAppeareTween.current = getAnimationTimeline().call(() => {
        setIsAppearing(false)
        setIsAppeared(true)
      })
    }
  }, [isAppearing, getAnimationTimeline])

  React.useEffect(() => {
    if (
      (isAppeared || animTimelineResetStyles) &&
      bgRef.current &&
      blackoutRef.current &&
      textRef.current
    ) {
      bgRef.current.style = {}
      blackoutRef.current.style = {}
      textRef.current.style = {}
    }
  }, [isAppeared, animTimelineResetStyles])

  React.useEffect(() => {
    if (
      isOuterAnimating &&
      typeof animTimeline === 'function' &&
      !outerTween.current
    ) {
      outerTween.current = animTimeline({
        wrapper: wrapperRef.current,
        bg: bgRef.current,
        text: textRef.current,
        blackout: blackoutRef.current,
      }).call(() => {
        if (outerTween.current) {
          outerTween.current.kill()
        }

        setIsOuterAnimating(false)
      })
    }
  }, [isOuterAnimating, animTimeline])

  React.useEffect(() => {
    if (!isOuterAnimating && outerTween.current) {
      if (animTimelineCb && typeof animTimelineCb === 'function') {
        animTimelineCb()
        outerTween.current = null
      }
    }
  }, [animTimelineCb, isOuterAnimating])

  React.useEffect(() => {
    return () => {
      if (timerDelay.current) {
        clearTimeout(timerDelay.current)
      }

      if (btnAppeareTween.current) {
        btnAppeareTween.current.kill()
      }

      if (outerTween.current) {
        outerTween.current.kill()
      }
    }
  }, [])

  const renderButtonInner = () => {
    const iconContent = icon ? <IconBox>{icon}</IconBox> : null

    if (children) {
      if (typeof children === 'object') {
        //
        // Render custom children
        return (
          <ContentBox ref={textRef} textStyles={textStyles}>
            {iconContent}
            {children}
          </ContentBox>
        )
      } else {
        //
        // Render string children with padding around
        return (
          <TextBox ref={textRef} textStyles={textStyles}>
            {iconContent}
            <Text as="span" dangerouslySetInnerHTML={{ __html: children }} />
          </TextBox>
        )
      }
    } else if (iconContent) {
      //
      // Render Icon without children without padding around
      // Icon would be centered
      return (
        <ContentBox ref={textRef} textStyles={textStyles}>
          {iconContent}
          {successText ? (
            <SuccessText>
              <Text
                as="span"
                dangerouslySetInnerHTML={{ __html: successText }}
              />
            </SuccessText>
          ) : null}
        </ContentBox>
      )
    } else {
      //
      // Render centered text
      return (
        <TextBox ref={textRef} textStyles={textStyles}>
          <Text as="span" dangerouslySetInnerHTML={{ __html: children }} />
        </TextBox>
      )
    }
  }

  const inner = (
    <>
      <Bg ref={bgRef} bgStyles={bgStyles} />
      <Blackout ref={blackoutRef} blackoutStyles={blackoutStyles} />
      {renderButtonInner()}
    </>
  )

  const resultWrapperProps = {
    ...wrapperProps,
    isDisabled,
    size,
    notHoverable: notHoverable || (animation && !isAppeared),
    isAnimating: isOuterAnimating || (animation && !isAppeared),
    isAppearance: animation && !isAppeared,
    withTopMarginOnMobile,
  }

  let props = null

  switch (as) {
    case 'button':
      props = {
        as: 'button',
        ref: wrapperRef,
      }
      break
    case 'Link':
      props = {
        as: Link,
        innerRef: node => (wrapperRef.current = node),
        to,
        asPath,
      }
      break
    default:
      props = {
        as: 'a',
        href: to,
        target: '_blank',
        rel: 'noopener noreferrer',
        ref: wrapperRef,
      }
      break
  }

  return (
    <Wrapper {...props} {...resultWrapperProps}>
      {inner}
    </Wrapper>
  )
}

ButtonRoundedBaseSt.propTypes = {
  /** Icon inside button */
  icon: PropTypes.node,

  /** Title of button */
  children: PropTypes.node,

  /** False or type of appearance animation */
  animation: PropTypes.oneOf([
    false,
    ANIMATION_LEFT,
    ANIMATION_CENTER,
    ANIMATION_RIGHT,
  ]),

  /** Customize delay for appearance animation */
  animationDelay: PropTypes.number,

  /** Animation timeline  */
  animTimeline: PropTypes.func,

  /** Animation timeline end callback  */
  animTimelineCb: PropTypes.func,

  /** Animation timeline end flag to reset styles  */
  animTimelineResetStyles: PropTypes.bool,

  /** Type of wrapper component */
  as: PropTypes.oneOf(['a', 'button', 'Link']),

  /** String for client-routing for Link component */
  asPath: PropTypes.string,

  /** Styles for background tag */
  bgStyles: PropTypes.array,

  /** Styles for blackout tag */
  blackoutStyles: PropTypes.array,

  /** Flag to control disable state */
  isDisabled: PropTypes.bool,

  /** Flag to control button reaction on hover  */
  notHoverable: PropTypes.bool,

  /** Size of component */
  /** fit - not determined button size, manipulated by content */
  size: PropTypes.oneOf(['fit', 'slim', 'base', 'medium', 'large']),

  /** Success text */
  successText: PropTypes.string,

  /** Styles for text tag */
  textStyles: PropTypes.array,

  /** Use this prop for 'Link' and 'a'  */
  to: PropTypes.string,

  /** Flag to set 2px margin-top on mobile */
  withTopMarginOnMobile: PropTypes.bool,
}

ButtonRoundedBaseSt.defaultProps = {
  children: '',
  as: 'a',
  to: '',
  size: 'base',
  animation: false,
  animationDelay: 0,
  animTimelineResetStyles: false,
  notHoverable: false,
  isDisabled: false,
}

export default ButtonRoundedBaseSt
