import PropTypes from 'prop-types'
import React from 'react'

import { SIZE_MAP } from './Cost'
import { CostWrapper } from './styles'

const CostPreloader = ({ className, size }) => {
  let Component = React.useMemo(() => SIZE_MAP[size], [size])

  return (
    <CostWrapper {...{ className }}>
      <Component as="div">&nbsp;</Component>
    </CostWrapper>
  )
}

CostPreloader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SIZE_MAP)),
}

CostPreloader.defaultProps = {
  className: '',
  size: 'h2',
}

export default CostPreloader
