import { em } from 'polished'
import styled, { css } from 'styled-components'

const textCss = css`
  position: ${p => (p.isLongest ? 'relative' : 'absolute')};
  top: 0;
  left: 0;
  font-family: ${p => p.theme.typography.baseFont};
  text-align: center;
  height: 100%;
  -webkit-backface-visibility: hidden;
  color: ${p => p.theme.colors.white};
`

export const IconBox = styled.div`
  position: relative;
  padding: ${em(10)};
  height: 100%;
`

export const ContentBox = styled.div`
  ${textCss};
  position: absolute;
  display: ${p => (p.isActive ? 'block' : 'none')};
  margin: 0 auto;

  ${p =>
    p.textStyles &&
    css`
      ${p.textStyles};
    `}
`

export const TextBox = styled.div`
  ${textCss};
  display: flex;
  opacity: ${p => (p.isActive ? 1 : 0)};
  align-items: center;
  padding: 0 ${p => p.theme.buttons.buttonRoundedTextIndent};
  justify-content: center;
  transition: color ${p => p.theme.animation.timeBtns}
    ${p => p.theme.animation.cubicBezier};
  white-space: nowrap;
  user-select: none;

  ${p =>
    !p.isLongest &&
    css`
      width: 100%;
    `}

  ${p =>
    p.textStyles &&
    css`
      ${p.textStyles};

      ${IconBox} svg {
        fill: currentColor !important;
        stroke: currentColor !important;
      }
    `}
`
