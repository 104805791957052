import CSSPlugin from 'gsapAlias/CSSPlugin'
import TimelineLite from 'gsapAlias/TimelineLite'

// eslint-disable-next-line no-unused-vars
const plugins = [CSSPlugin]

const WHOLE = 1.2

const GROWING = WHOLE / 3
const INCREASE_WIDTH = WHOLE / 2
const FADE_IN = WHOLE / 3
const FADE_IN_OVERLAP = INCREASE_WIDTH / 3

export const showButtonFromLeft = (wrapperNode, bgNode, textNode) => {
  const timeline = new TimelineLite()
  const { height, width } = wrapperNode.getBoundingClientRect()

  timeline
    .set(bgNode, {
      scaleX: 0,
      scaleY: 0,
      width: height,
    })
    .set(textNode, {
      y: 15,
      opacity: 0,
    })
    .to(bgNode, GROWING, {
      scaleX: 1,
      scaleY: 1,
    })
    .to(bgNode, INCREASE_WIDTH, {
      scaleX: 1,
      scaleY: 1,
      width: width,
    })
    .to(
      textNode,
      FADE_IN,
      {
        y: 0,
        opacity: 1,
      },
      `-=${FADE_IN_OVERLAP}`,
    )

  return timeline
}

export const showButtonFromRight = (wrapperNode, bgNode, textNode) => {
  const timeline = new TimelineLite()
  const { height, width } = wrapperNode.getBoundingClientRect()

  timeline
    .set(bgNode, {
      scaleX: 0,
      scaleY: 0,
      x: width - height,
      width: height,
    })
    .set(textNode, {
      y: 15,
      opacity: 0,
    })
    .to(bgNode, GROWING, {
      scaleX: 1,
      scaleY: 1,
    })
    .to(bgNode, INCREASE_WIDTH, {
      scaleX: 1,
      scaleY: 1,
      x: 0,
      width: width,
    })
    .to(
      textNode,
      FADE_IN,
      {
        y: 0,
        opacity: 1,
      },
      `-=${FADE_IN_OVERLAP}`,
    )

  return timeline
}

export const showButtonFromCenter = (wrapperNode, bgNode, textNode) => {
  const timeline = new TimelineLite()
  const { height, width } = wrapperNode.getBoundingClientRect()
  const btnCenter = (width - height) / 2

  timeline
    .set(bgNode, {
      scaleX: 0,
      scaleY: 0,
      x: btnCenter,
      width: height,
    })
    .set(textNode, {
      y: 15,
      opacity: 0,
    })
    .to(bgNode, GROWING, {
      scaleX: 1,
      scaleY: 1,
    })
    .to(bgNode, INCREASE_WIDTH + 0.1, {
      x: 0,
      width: width,
    })
    .to(
      textNode,
      FADE_IN,
      {
        y: 0,
        opacity: 1,
      },
      `-=${FADE_IN_OVERLAP + 0.1}`,
    )

  return timeline
}
