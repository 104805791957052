import styled, { css } from 'styled-components'

const bodyColor = `${p => p.theme.colors.bodyColor}`
const white = `${p => p.theme.colors.white}`

const mobOnlyCentered = css`
  right: 0;
  text-align: center;

  ${p => p.theme.mq.upMd} {
    right: auto;
    text-align: inherit;
  }
`

const tabNotCentered = css`
  right: 0;
  text-align: center;

  ${p => p.theme.mq.upMd} {
    right: auto;
    text-align: inherit;
  }

  ${p => p.theme.mq.upLg} {
    right: 0;
    text-align: center;
  }
`

const positions = {
  ['top']: css`
    top: 0;
    transform: translateY(-80%);

    ${p => p.theme.mq.upMd} {
      top: 0;
      transform: translateY(-80%);
    }
  `,
  ['bottom']: css`
    top: 2em;

    ${p => p.theme.mq.upMd} {
      top: 2em;
    }
  `,
}

const rightAlign = css`
  right: 0;
  left: initial;
  text-align: right;
`

export const SaleWrapper = styled.div`
  display: inline-block;
  position: relative;
`

export const SaleCost = styled.div`
  position: absolute;
  left: 0;
  opacity: ${props => (props.isTransparent ? '.4' : '1')};
  color: ${props => (props.isWhite ? bodyColor : white)};
  ${props => props.isCenter && 'right: 0;'}
  ${props => props.isRight && rightAlign}
  ${props => props.isMobileCenter && mobOnlyCentered}
  ${props => props.isTabNotCenter && tabNotCentered}
  ${props => positions[props.position]}
`
