import { rgba } from 'polished'

export const COLOR_SUCCESS = '#6adf63'
export const COLOR_FAILED = '#ff5c61'
export const COLOR_FAILED_OP_06 = '#ffa1a4'
export const COLOR_DEFAULT = '#8180fa'

export const COLOR_WHITE = '#FFFFFF'
export const COLOR_WHITE_MUTED = '#d6d6d6'

export const COLOR_BLACK = '#000000'
export const COLOR_BLACK_MUTED = '#a3a3a3'

export const COLOR_GRAY_OP_10 = '#ededed'

export const transparent = 'transparent'

export const white = 'white'
export const whiteOp7 = rgba(white, 0.7) // text color with transparency (features plate on wgs)
export const whiteOp6 = rgba(white, 0.6) // text color with transparency
export const whiteOp5 = rgba(white, 0.5) // text color with transparency (footer on wgs)
export const whiteOp4 = rgba(white, 0.4) // text color with transparency (features plate on wgs)
export const whiteOp3 = rgba(white, 0.3) // background + borders
export const whiteOp2 = rgba(white, 0.2) // background + wgs compare bar
export const whiteOp15 = rgba(white, 0.15) // line color for wgs in the steps blocks
export const whiteOp12 = rgba(white, 0.12) // line color for wgs in the steps blocks
export const whiteOp1 = rgba(white, 0.1) // footer border color on not found page
export const whiteOp07 = rgba(white, 0.07) // border color (results block on wgs)
export const whiteOp05 = rgba(white, 0.05) // border (features plate on wgs)

export const black = 'black' // text, buttons bg, classnames
export const bodyColor = black // alternative name
export const blackOp05 = rgba(black, 0.05) // buttons blackout for light bgs and highlight of white elements
export const blackOp06 = rgba(black, 0.06) // buttons blackout for light bgs and highlight of white elements
export const blackOp07 = rgba(black, 0.07) // buttons blackout + borders bg + borders ?
export const blackOp07NonTransp = '#ededed' // menu borders
export const blackOp15 = rgba(black, 0.15) // slider overlay color
export const blackOp1 = rgba(black, 0.1) // borders for buttons && some unique objects
export const blackOp2 = rgba(black, 0.2) // ?
export const blackOp3 = rgba(black, 0.3) // ?
export const blackOp4 = rgba(black, 0.4) // text, custom scroll bar color and shadow color
export const blackOp5 = rgba(black, 0.5) // check color
export const blackOp6 = rgba(black, 0.6) // button bg, overlay color
export const blackOp7 = rgba(black, 0.7)
export const blackOp75 = rgba(black, 0.75) // cookie banner bg
export const blackOp85 = rgba(black, 0.85) // black tooltip bg
export const dustyBlack = '#0D0D0D'

export const lighterGray = '#c4c4c4'
export const lighterGrayOp3 = rgba(lighterGray, 0.3)
export const gray = '#4d4d4d'
export const basicGray = '#808080'
export const grayOp6 = rgba(gray, 0.6) // muted text
export const aquaHaze = '#eef3f5'
export const muted = grayOp6 // alternative name
export const grayOp4 = rgba(gray, 0.4) // ??
export const grayOp07 = rgba(gray, 0.07) // borders ?
export const grayOp06NonTransp = '#f5f5f5' // site blocks background

export const materialRed = '#ff5c61' //input error underline color + unordered list
export const materialRedOp6 = rgba(materialRed, 0.6) //input error bg and text color
export const cinnabar = '#E84136' // banner bg
export const mistyRose = '#FFE3E3'
export const venetianRed = '#FF7571'

export const yellowSun = '#FFE870'
export const yellow = '#FDB432'
export const orange = '#fbb342' // unordered list + buttons bg
export const coral = '#FF974B' // Tim's adventure bg
export const yellowDark = '#FDB432'
export const grass = '#00DA67' // unordered list + buttons bg + backgrounds
export const grassBg = '#01DA67' // blog section
export const americanGreen = '#42C02E' // banner color
export const funGreen = '#007039'
export const tePapaGreen = '#244437'

export const perfume = '#b2b2f9' // backgrounds
export const lilac = '#8a88fa' // backgrounds
export const lilacDark = '#6439D0' // buttons bg & bullets
export const heliotrope = '#9471ff' // main page — bg of block with health control centre

export const catskillWhite = '#deecef' // unordered list + backgrounds
export const cerulean = '#00BEFF' // steps on wgs
export const deepCerulean = '#017BB1' // bg
export const pictonBlue = '#30C4F2' // backgrounds + buttons bg
export const blueDark = '#007cb2' // backgrounds + buttons bg
export const blue = '#2E82F6' // backgrounds
export const curiousBlue = '#22B1E1' // order test block bg
export const seaBlue = '#007CB2' // banner in cart
export const naturalRed = '#fd0200' // header + buttons bg when black friday is active
export const monza = '#D60214' // new year banner

export const pink = '#E961A0' // banner bg
export const pink_svd = '#FF5C61'
export const whiskey = '#EEB97C' // quotes slider background
export const purpleHeart = '#6439D0' // backgrounds for blocks on wgs pages
export const purpleHeartDark = '#5C32C5' // backgrounds for blocks on wgs pages
export const mineShaft = '#212121' // feature plates and faq background on wgs pages
export const mineShaft2 = '#292929' // feature plates and faq background on wgs pages
export const grayOp06NonTranspOp01 = rgba(grayOp06NonTransp, 0.1) // steps on wgs pages
export const macaroniAndCheese = '#FEBF70' // steps on wgs, consultation background
export const godGray = '#171717' // background
export const raisinBlack = '#232323'
export const grayishBlue = '#9FB5CE'

export const alto = '#d8d8d8' // background
export const altoOp1 = rgba(alto, 0.1) // social icon background on wgs
export const wePeep = '#F5D3D3' // background
export const wePeepOp1 = rgba(wePeep, 0.1) // protection circle background on wgs

export const pistachio = '#E0F66A'
export const lightYellow = '#B7FF5C'
export const softYellow = '#EAFC7A'
