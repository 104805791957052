import React from 'react'

export default ({ inView }) => {
  const [swiperLoaded, setSwiperLoaded] = React.useState(false)
  const Swiper = React.useRef(null)

  React.useEffect(() => {
    if (inView && !swiperLoaded) {
      const loadSwiper = async () => {
        Swiper.current = (await import('react-id-swiper')).default
        setSwiperLoaded(true)
      }

      loadSwiper()
    }
  }, [inView, swiperLoaded])

  return {
    Swiper: Swiper.current,
    swiperLoaded,
  }
}
