export const getFormErrors = ({
  fetchError = {},
  errorCode = null,
  customErrors = [],
}) => {
  return {
    fetchError,
    errorCode,
    submitErrors: customErrors,
  }
}
