import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'

import Icon from 'components/Icon'
import Link from 'components/Link'
import { Text } from 'styles/components/typography'

import {
  showButtonFromLeft,
  showButtonFromCenter,
  showButtonFromRight,
} from './animations'

import './ButtonRoundedBase.scss'

const [ANIMATION_LEFT, ANIMATION_CENTER, ANIMATION_RIGHT] = [
  'left',
  'center',
  'right',
]

/**
 * Component of Button with rounded corners
 * @return {Component} Component — ButtonRoundedBase
 */
export default class ButtonRoundedBase extends Component {
  static propTypes = {
    /** False or type of appearance animation */
    animation: PropTypes.oneOf([
      false,
      ANIMATION_LEFT,
      ANIMATION_CENTER,
      ANIMATION_RIGHT,
    ]),

    /** Customize delay for appearance animation */
    animationDelay: PropTypes.number,

    /** Animation timeline  */
    animTimeline: PropTypes.func,

    /** Animation timeline end callback  */
    animTimelineCb: PropTypes.func,

    /** Animation timeline end flag to reset styles  */
    animTimelineResetStyles: PropTypes.bool,

    /** Type of wrapper component */
    as: PropTypes.oneOf(['a', 'button', 'Link']),

    /** String for client-routing for Link component */
    asPath: PropTypes.string,

    /** ClassName with color for background tag */
    bgClassName: PropTypes.string,

    /** ClassName with color for blackout tag */
    blackoutClassName: PropTypes.string,

    /** Title of button */
    children: PropTypes.node,

    /** Id of icon — [look Icon component](#!/Icon) */
    icon: PropTypes.string,

    /** Color of Icon. When hover it will become white */
    iconColor: PropTypes.string,

    /** Classname with align of Icon. */
    iconClassName: PropTypes.string,

    /** Thickness of Icon's border */
    iconStrokeWidth: PropTypes.string,

    /** Flag to control disable state */
    isDisabled: PropTypes.bool,

    /** Flag to control button reaction on hover  */
    notHoverable: PropTypes.bool,

    /** Ref for icon to animate it (example: cart button)  */
    refIcon: PropTypes.object,

    /** Size of component */
    size: PropTypes.oneOf(['fit', 'slim', 'base', 'medium', 'large']),

    /** Success text */
    successText: PropTypes.string,

    /** ClassName for text tag */
    textClassName: PropTypes.string,

    /** Use this prop for 'Link' and 'a'  */
    to: PropTypes.string,

    /** Flag to set 2px margin-top on mobile */
    withTopMarginOnMobile: PropTypes.bool,

    /** Class names wrapper component */
    wrapperClassName: PropTypes.string,

    /** Class names wrapper component */
    className: PropTypes.string,

    /** Extra props for wrapper component */
    wrapperProps: PropTypes.object,
  }

  static defaultProps = {
    children: '',
    as: 'a',
    to: '',
    size: 'base',
    bgClassName: 'bg--purple',
    blackoutClassName: 'bg--black--op-07',
    textClassName: 'text--white',
    animation: false,
    animationDelay: 0,
    wrapperProps: {},
    wrapperClassName: '',
    animTimelineResetStyles: false,
    notHoverable: false,
    isDisabled: false,
    refIcon: null,
  }

  constructor(props) {
    super(props)

    this.timerDelay = null
    this.btnAppeareTween = null
    this.outerTween = null
    this.wrapper = React.createRef()
    this.bg = React.createRef()
    this.blackout = React.createRef()
    this.text = React.createRef()

    this.state = {
      isAppearing: false,
      isAppeared: !props.animation, // need this flag cause appearance animation delay
      isOuterAnimating: !!props.animTimeline,
      animTimeline: null,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { animTimeline } = nextProps

    if (animTimeline && animTimeline !== prevState.animTimeline) {
      return {
        isOuterAnimating: true,
        animTimeline,
      }
    }

    return null
  }

  componentDidMount() {
    const { animation, animationDelay } = this.props

    if (animation) {
      this.timerDelay = setTimeout(() => {
        this.setState({
          isAppearing: true,
        })
      }, animationDelay)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isAppearing, isOuterAnimating, animTimeline } = this.state
    const { animation, animTimelineCb, animTimelineResetStyles } = this.props
    const wrapper = this.wrapper.current
    const bg = this.bg.current
    const blackout = this.blackout.current
    const text = this.text.current

    if (isAppearing && isAppearing !== prevState.isAppearing) {
      this.btnAppeareTween = this.getAnimationTimeline(animation)
      this.btnAppeareTween = this.btnAppeareTween.call(() => {
        this.setState(
          {
            isAppearing: false,
            isAppeared: true,
          },
          () => {
            bg.style = {}
            blackout.style = {}
            text.style = {}
          },
        )
      })
    }

    if (
      isOuterAnimating &&
      !prevState.isOuterAnimating &&
      typeof animTimeline === 'function'
    ) {
      this.outerTween = animTimeline({ wrapper, bg, text, blackout }).call(
        () => {
          if (this.outerTween) {
            this.outerTween.kill()
          }
          this.setState(
            {
              isOuterAnimating: false,
            },
            () => {
              if (animTimelineResetStyles) {
                bg.style = {}
                blackout.style = {}
                text.style = {}
              }
              if (animTimelineCb && typeof animTimelineCb === 'function') {
                animTimelineCb()
              }
            },
          )
        },
      )
    }
  }

  componentWillUnmount() {
    if (this.timerDelay) {
      clearTimeout(this.timerDelay)
    }
    if (this.btnAppeareTween) {
      this.btnAppeareTween.kill()
    }
    if (this.outerTween) {
      this.outerTween.kill()
    }
  }

  /**
   * Component of Button with rounded corners
   * @param {string} animationType - Type of appearance animation
   * @return {Component} Component — TimeLine for appearance animation
   */
  getAnimationTimeline = animationType => {
    const wrapper = this.wrapper.current
    const bg = this.bg.current
    const text = this.text.current

    switch (animationType) {
      case ANIMATION_LEFT:
        return showButtonFromLeft(wrapper, bg, text)
      case ANIMATION_CENTER:
        return showButtonFromCenter(wrapper, bg, text)
      case ANIMATION_RIGHT:
        return showButtonFromRight(wrapper, bg, text)
      default:
        return null
    }
  }

  renderButtonInner = () => {
    const {
      children,
      bgClassName,
      blackoutClassName,
      textClassName,
      icon,
      iconColor,
      iconStrokeWidth,
      iconClassName,
      refIcon,
      successText,
    } = this.props
    const iconContent =
      icon && iconColor ? (
        <Icon
          id={icon}
          color={iconColor}
          strokeWidth={iconStrokeWidth}
          className={`button-rounded-base__icon ${iconClassName}`}
          wrapperRef={refIcon}
        />
      ) : null
    let content = null

    if (children && typeof children === 'object' && !iconContent) {
      //
      // Render custom children
      content = (
        <span ref={this.text} className={`content ${textClassName}`}>
          {children}
        </span>
      )
    } else if (children && typeof children !== 'object' && iconContent) {
      //
      // Render Icon with string children with padding around
      content = (
        <span ref={this.text} className={`text ${textClassName}`}>
          {iconContent}
          <Text as="span" dangerouslySetInnerHTML={{ __html: children }} />
        </span>
      )
    } else if (children && typeof children === 'object' && iconContent) {
      //
      // Render Icon with object children with padding around
      content = (
        <span ref={this.text} className={`content ${textClassName}`}>
          {iconContent}
          {children}
        </span>
      )
    } else if (!children && iconContent && !successText) {
      //
      // Render Icon without children without padding around and without success text
      // Icon would be centered
      content = (
        <span ref={this.text} className={`content ${textClassName}`}>
          {iconContent}
        </span>
      )
    } else if (!children && iconContent && successText) {
      //
      // Render Icon without children without padding around and with success text
      // Icon would be centered
      content = (
        <span ref={this.text} className={`content ${textClassName}`}>
          {iconContent}
          {successText ? (
            <div className="button-rounded-base__success-text ml-1 ml-lg-0">
              <Text
                as="span"
                dangerouslySetInnerHTML={{ __html: successText }}
              />
            </div>
          ) : null}
        </span>
      )
    } else {
      //
      // Render centered text
      content = (
        <div ref={this.text} className={`text ${textClassName}`}>
          <Text as="span" dangerouslySetInnerHTML={{ __html: children }} />
        </div>
      )
    }

    return (
      <Fragment>
        <div ref={this.bg} className={`bg ${bgClassName}`} />
        <div ref={this.blackout} className={`blackout ${blackoutClassName}`} />
        {content}
      </Fragment>
    )
  }

  render() {
    const {
      as,
      size,
      animation,
      wrapperProps,
      to,
      asPath,
      notHoverable,
      isDisabled,
      wrapperClassName,
      withTopMarginOnMobile,
      className,
    } = this.props
    const { isAppeared, isOuterAnimating } = this.state
    const inner = this.renderButtonInner()
    let outerClass = `button button-rounded-base button-rounded-base--${size} `

    if (isDisabled) {
      outerClass += 'button-rounded-base--disabled '
    }

    if (notHoverable || (animation && !isAppeared)) {
      outerClass += 'button-rounded-base--non-hoverable '
    }

    if (isOuterAnimating || (animation && !isAppeared)) {
      outerClass += 'button-rounded-base--animating '
    }

    if (animation && !isAppeared) {
      outerClass += 'button-rounded-base--appearance '
    }

    if (withTopMarginOnMobile) {
      outerClass += 'button-rounded-base--with-mobile-top-margin '
    }

    const resultWrapperProps = {
      className: `${outerClass} ${wrapperClassName} ${className}`,
      ...wrapperProps,
    }

    switch (as) {
      case 'button':
        return (
          <button ref={this.wrapper} {...resultWrapperProps}>
            {inner}
          </button>
        )
      case 'Link':
        return (
          <Link
            innerRef={node => {
              this.wrapper = { current: node }
            }}
            to={to}
            asPath={asPath}
            {...resultWrapperProps}
          >
            {inner}
          </Link>
        )
      default:
        return (
          <a
            href={to}
            target="_blank"
            rel="noopener noreferrer"
            ref={this.wrapper}
            {...resultWrapperProps}
          >
            {inner}
          </a>
        )
    }
  }
}
