import sentry from 'utils/sentry'

export const getCookie = function getCookie(name) {
  if (process.browser) {
    let matches = document.cookie.match(
      new RegExp(
        '(?:^|; )' +
          name.replace(/([.$?*|{}()[]\\\/\+\^])/g, '\\$1') +
          '=([^;]*)',
      ),
    )

    let result = null

    try {
      if (matches) {
        result = decodeURIComponent(matches[1])
      }
    } catch (error) {
      sentry.captureMessageWithScope('Cookie decode error', {
        error,
        matches,
      })
    }

    return result
  }

  return undefined
}

export const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
