let he

export default function(html) {
  if (process.browser) {
    var textArea = document.createElement('textarea')
    textArea.innerHTML = html

    return textArea.value
  } else {
    if (!he) {
      he = require('he')
    }

    return he.decode(html)
  }
}
