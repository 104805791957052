import styled, { css } from 'styled-components'

export const Picture = styled.picture`
  height: inherit;
`

export const Image = styled.img`
  height: inherit;

  ${({ fitOption }) =>
    fitOption === 'contain' &&
    css`
      object-fit: contain;
      width: 100%;
    `}

  ${({ fitOption }) =>
    fitOption === 'cover' &&
    css`
      object-fit: cover;
      object-position: top left;
      width: 100%;
    `}
`
