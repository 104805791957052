import { math } from 'polished'
import styled, { css } from 'styled-components'

import { BoxBase } from 'styles/components/layout'

export const Box = styled.div`
  position: relative;
  padding-left: ${p => p.theme.indents.baseIndentXs};
  padding-right: ${p => p.theme.indents.baseIndentXs};

  ${p => p.theme.mq.upLg} {
    padding-left: ${p => math(`${p.theme.indents.baseBoxIndentLg} / 2`)};
    padding-right: ${p => math(`${p.theme.indents.baseBoxIndentLg} / 2`)};
  }
`

export const BoxBaseStyled = styled(BoxBase)`
  position: relative;
`

export const Wrapper = styled.section`
  position: relative;

  ${({ isOverflowHidden }) => isOverflowHidden && `overflow: hidden;`}

  ${({ withIndents }) =>
    withIndents &&
    css`
      margin: ${p => p.theme.indents.baseIndentXs};

      ${p => p.theme.mq.upLg} {
        margin: ${p => math(`${p.theme.indents.baseBoxIndentLg} / 2`)};
      }
    `}
`

export const Inner = styled.div`
  position: relative;
  height: inherit;

  ${({ isWideToDesktop }) =>
    isWideToDesktop &&
    css`
      margin-left: -${p => p.theme.indents.baseIndentXs};
      margin-right: -${p => p.theme.indents.baseIndentXs};

      ${p => p.theme.mq.upMd} {
        margin-left: -${p => p.theme.indents.baseIndentMd};
        margin-right: -${p => p.theme.indents.baseIndentMd};
      }

      ${p => p.theme.mq.upLg} {
        margin-left: 0;
        margin-right: 0;
      }
    `}
`

export const Overlay = styled.div`
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${p => p.theme.colors.blackOp4};
`
