import PropTypes from 'prop-types'
import React from 'react'

import { Picture, Image } from './styles'

const RULE_MOBILE = '(max-width: 767px)'
const RULE_TABLET = '(min-width: 768px)'
const RULE_DESKTOP = '(min-width: 1200px)'
const RULE_DESKTOP_LG = '(min-width: 1600px)'
const ORDER = [
  'uhd',
  'retina',
  'desktop',
  'tablet3x',
  'tablet2x',
  'tablet',
  'mobile3x',
  'mobile2x',
  'mobile',
]
const DEFAULT_IMAGE_QUALITY = {
  mobile: 100,
  mobile2x: 90,
  mobile3x: 80,
  tablet: 100,
  tablet2x: 90,
  tablet3x: 80,
  desktop: 100,
  retina: 95,
  uhd: 80,
}
const mapTypes = {
  jpg: 'jpeg',
  jpeg: 'jpeg',
  png: 'png',
  webp: 'webp',
}

/**
 * DynamicImage component
 *
 * Use it when you need responsive image
 * @return {Component} Component - DynamicImage
 */

const DynamicImage = ({
  image,
  imageType,
  fitOption,
  className,
  imageSizes,
  quality,
  alt,
  namesByBreakpoints,
}) => {
  const mergedQuality = React.useMemo(
    () => ({ ...DEFAULT_IMAGE_QUALITY, ...quality }),
    [quality],
  )
  const configXs = React.useMemo(() => {
    if (imageSizes.xs) {
      let postfix = namesByBreakpoints ? '_mob' : ''

      return {
        mobile: {
          rule: RULE_MOBILE,
          postfix: postfix,
          width: imageSizes.xs,
        },
        mobile2x: {
          rule: `(-webkit-min-device-pixel-ratio: 2) and ${RULE_MOBILE}, (min-resolution: 87dpi) and ${RULE_MOBILE}`,
          postfix: postfix,
          width: imageSizes.xs * 2,
        },
        mobile3x: {
          rule: `(-webkit-min-device-pixel-ratio: 3) and ${RULE_MOBILE}, (min-resolution: 288dpi) and ${RULE_MOBILE}`,
          postfix: postfix,
          width: imageSizes.xs * 3,
        },
      }
    }

    return null
  }, [imageSizes.xs, namesByBreakpoints])
  const configMd = React.useMemo(() => {
    if (imageSizes.md && !imageSizes.lg) {
      return {
        desktop: {
          rule: RULE_TABLET,
          postfix: '',
          width: imageSizes.md,
        },
        retina: {
          rule: `(-webkit-min-device-pixel-ratio: 2) and ${RULE_TABLET}, (min-resolution: 87dpi) and ${RULE_TABLET}, ${RULE_DESKTOP_LG}`,
          postfix: '',
          width: imageSizes.md * 2,
        },
        uhd: {
          rule: '(min-width: 2000px)',
          postfix: '',
          width: imageSizes.md * 3,
        },
      }
    } else if (imageSizes.md && imageSizes.lg) {
      let postfix = namesByBreakpoints ? '_tab' : ''

      return {
        tablet: {
          rule: RULE_TABLET,
          postfix: postfix,
          width: imageSizes.md,
        },
        tablet2x: {
          rule: `(-webkit-min-device-pixel-ratio: 2) and ${RULE_TABLET}, (min-resolution: 87dpi) and ${RULE_TABLET}`,
          postfix: postfix,
          width: imageSizes.md * 2,
        },
        tablet3x: {
          rule: `(-webkit-min-device-pixel-ratio: 3) and ${RULE_TABLET}, (min-resolution: 288dpi) and ${RULE_TABLET}`,
          postfix: postfix,
          width: imageSizes.md * 3,
        },
        desktop: {
          rule: RULE_DESKTOP,
          postfix: '',
          width: imageSizes.lg,
        },
        retina: {
          rule: `(-webkit-min-device-pixel-ratio: 2) and ${RULE_DESKTOP}, (min-resolution: 87dpi) and ${RULE_DESKTOP}, ${RULE_DESKTOP_LG}`,
          postfix: '',
          width: imageSizes.lg * 2,
        },
        uhd: {
          rule: '(min-width: 2000px)',
          postfix: '',
          width: imageSizes.lg * 3,
        },
      }
    }

    return null
  }, [imageSizes.md, imageSizes.lg, namesByBreakpoints])

  const config = React.useMemo(() => ({ ...configXs, ...configMd }), [
    configXs,
    configMd,
  ])
  const BREAKPOINTS = React.useMemo(() => Object.keys(config), [config])

  const getImagePath = React.useCallback(
    (breakpoint, imageType) => {
      if (breakpoint in config) {
        return `${image}${config[breakpoint].postfix}.${imageType}`
      }

      return `${image}.${imageType}`
    },
    [config, image],
  )

  const defaultImageSrc = React.useMemo(
    () =>
      imageSizes.md
        ? getImagePath('desktop', imageType)
        : getImagePath('mobile', imageType),
    [imageType, getImagePath, imageSizes.md],
  )

  return (
    <Picture>
      {ORDER.reduce((arr, code) => {
        if (BREAKPOINTS.includes(code)) {
          const srcSetName = getImagePath(code, imageType)
          if (srcSetName) {
            arr.push(
              <source
                key={`${code}.webp`}
                media={config[code].rule}
                srcSet={`${srcSetName}?w=${config[code].width}&q=${mergedQuality[code]}&format=webp`}
                type={`image/${mapTypes.webp}`}
              />,
              <source
                key={code}
                media={config[code].rule}
                srcSet={`${srcSetName}?w=${config[code].width}&q=${mergedQuality[code]}&format=${imageType}`}
                type={`image/${mapTypes[imageType]}`}
              />,
            )
          }
        }

        return arr
      }, [])}
      <Image
        draggable="false"
        src={`${defaultImageSrc}?w=50&q=5`}
        {...{ alt, className, fitOption }}
      />
    </Picture>
  )
}

DynamicImage.propTypes = {
  /** Image alt; You don't have chance to skip it now */
  alt: PropTypes.string.isRequired,

  /** Image path */
  image: PropTypes.string.isRequired,

  /** Class name for image */
  className: PropTypes.string,

  /** Type of image fit behaviour */
  fitOption: PropTypes.oneOf(['contain', 'cover', 'none']),

  /** Image type */
  imageType: PropTypes.oneOf(['jpg', 'jpeg', 'png']),

  /** Image sizes by breakpoints */
  imageSizes: PropTypes.shape({
    xs: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
  }).isRequired,

  /** Image quality */
  quality: PropTypes.objectOf(PropTypes.number),

  /** Different names for dif breakpoints */
  namesByBreakpoints: PropTypes.bool.isRequired,
}

DynamicImage.defaultProps = {
  className: '',
  fitOption: 'cover',
  imageType: 'jpg',
  namesByBreakpoints: false,
  quality: {
    ...DEFAULT_IMAGE_QUALITY,
  },
}

export default DynamicImage
