import matchPath from 'react-router/matchPath'

const localization = process.env.localization
const DEPLOYMENT_REGION = process.env.region
let defaultLocale, locales

if (typeof localization === 'string') {
  const parsed = JSON.parse(localization)
  defaultLocale = parsed.defaultLocale
  locales = parsed.locales
} else {
  defaultLocale = localization.defaultLocale
  locales = localization.locales
}

const skipLocaleInUrl = locales.length === 1

// First of all:
// > In our app we use locale param, but it's not required
// > for example: in russian server we use urls without locale param
// > Therefore, we need the functions below to make a deal with it

// > Filter path-mask from awaited variables
const filterPathFromVariables = path => {
  return (
    path
      .split('/')
      .filter(item => item[0] !== ':')
      .join('/') || '/'
  )
}
// > Generate path with optional 'locale' parameter with restricted scope of values
export const serverPathWithLocale = path =>
  `/:locale(${locales.join('|')})?${path}`

// > Makes easier to define the link param of Route
const linkGenerator = path => {
  const safePath = filterPathFromVariables(path)

  return locale => (locale ? `${safePath}?locale=${locale}` : safePath)
}
// > Makes easier to define the as param of Route
const asGenerator = path => {
  const safePath = filterPathFromVariables(path)

  return locale =>
    locale && !skipLocaleInUrl ? `/${locale}${safePath}` : safePath
}

export const enhanceRoute = route => {
  return {
    ...route,

    // block route for direct link
    // (rendering on the server side)
    isBlockedForDirectLink: route.isBlockedForDirectLink || false,

    path: serverPathWithLocale(route.basePath), // for match function
    serverPath: serverPathWithLocale(route.basePath),
    renderPath: filterPathFromVariables(route.basePath),

    // parameters for link generation
    link: linkGenerator(route.basePath),
    as: asGenerator(route.basePath),
  }
}

export const enhanceModal = modal => {
  return {
    ...modal,
    link: locale => {
      const parentLink = modal.parent.link(locale)
      const prefix = locale ? '&' : '?'

      return `${parentLink}${prefix}modal=${modal.name}`
    },
    as: locale => {
      const parentAs = modal.parent.as(locale)
      const prefix = parentAs[parentAs.length - 1] === '/' ? '' : '/'

      return `${parentAs}${prefix}${modal.name}`
    },
  }
}

// > Set up fallback for location if it's not set up
export const getSafetyLocale = locale => locale || defaultLocale

// > Gives a component that we need to render
// > It is used with server rendering to prefetch needed data for chosen module (loadData attr)
export const getMatchedRouteAndParams = (routerConfig, requestedUrl) => {
  let match = {} // object of react router consists of route properties
  const matchedRoute = routerConfig.find(route => {
    match = matchPath(requestedUrl, { ...route, exact: true })

    return !!match
  })

  return {
    matchedRoute,
    match,
  }
}

// > Filters main menu items depending on locale
export const filterMainMenu = (menuMain, sections, locale = '') => {
  const result = []

  menuMain.forEach(menuItem => {
    if (
      (!menuItem.route.regions ||
        menuItem.route.regions.includes(DEPLOYMENT_REGION)) &&
      (!menuItem.route.hideForLocales ||
        !menuItem.route.hideForLocales.includes(locale)) &&
      (!menuItem.hideForLocales || !menuItem.hideForLocales.includes(locale)) &&
      (!menuItem.route.hideInNavForLocales ||
        !menuItem.route.hideInNavForLocales.includes(locale))
    ) {
      const newMenuItem = {
        ...menuItem,
        route: {
          ...menuItem.route,
          title: sections[menuItem.route.dict],
        },
      }

      if ('items' in menuItem && menuItem.items.length) {
        newMenuItem.items = filterMainMenu(menuItem.items, sections)
      }

      result.push(newMenuItem)
    }
  })

  return result
}
