// We are using this constants inside webpack config
// therefore you have to forget about es6

/**
 * есть локали, они же языки
 * есть страны в рамках которых можно переключаться на разные языки
 * есть регионы, они могут содержать как несколько стран, так и одну
 * Эти сущности не стоит путать между собой. Каждая служит своим целям,
 * хотя в некоторых местах может показаться, что они говорят об одном и том же.
 * Наиболее важным является разделение на регионы. Регион подразумевает свои
 * законодательные/юридические особенности, какие-то индивидуальные особенности
 * в БП (бизнес процесс) и т.д.
 * Так же важно отметить особенности UX. Они могут отличаться как на региональном
 * уровне, так и на уровне страны или локали.
 */

const RU = 'ru' // Russia
const UK = 'uk' // United Kingdom
const DE = 'de' // Germany
const SE = 'se' // Sweden
const NL = 'nl' // Netherlands
const BE = 'be' // Belgium
const LU = 'lu' // Luxembourg
const DK = 'dk' // Denmark
const FI = 'fi' // Finland
const IE = 'ie' // Ireland
const AT = 'at' // Austria
const PL = 'pl' // Poland
const CA = 'ca' // Canada
const IT = 'it' // Italy
const HU = 'hu' // Hungary
const NO = 'no' // Norway
const TR = 'tr' // Turkey
const JP = 'jp' // Japan
const US = 'us' // USA

const REGION_RU = 'russia'
const REGION_EU = 'europe'
const REGION_JP = 'japan'
const REGION_US = 'usa'

const REGIONS = {
  [REGION_RU]: {
    countries: [RU],
  },
  [REGION_EU]: {
    //countries: [UK, SE, NL, BE, LU, DK, FI, IE, PL, CA, IT, HU, NO],
    countries: [UK, NL, BE, DK, IE, IT],
  },
  [REGION_JP]: {
    countries: [JP],
  },
  [REGION_US]: {
    countries: [US],
  },
}

const COUNTRIES = {
  [RU]: {
    name: 'РУ',
    code: RU,
    intlCode: 'ru-RU',
    alpha2Code: 'RU',
    phoneCode: '+7',
  },
  [UK]: {
    name: 'UK',
    code: UK,
    intlCode: 'en-GB',
    alpha2Code: 'GB',
    phoneCode: '+44',
  },
  [DE]: {
    name: 'DE',
    code: DE,
    intlCode: 'de-DE',
    alpha2Code: 'DE',
    phoneCode: '+49',
  },
  [SE]: {
    name: 'SE',
    code: SE,
    intlCode: 'en-SE',
    alpha2Code: 'SE',
    phoneCode: '+46',
  },
  [NL]: {
    name: 'NL',
    code: NL,
    intlCode: 'en-NL',
    alpha2Code: 'NL',
    phoneCode: '+31',
  },
  [BE]: {
    name: 'BE',
    code: BE,
    intlCode: 'en-GB',
    alpha2Code: 'BE',
    phoneCode: '+32',
  },
  [LU]: {
    name: 'LU',
    code: LU,
    intlCode: 'en-LU',
    alpha2Code: 'LU',
    phoneCode: '+352',
  },
  [DK]: {
    name: 'DK',
    code: DK,
    intlCode: 'da',
    alpha2Code: 'DK',
    phoneCode: '+45',
  },
  [FI]: {
    name: 'FI',
    code: FI,
    intlCode: 'en-FI',
    alpha2Code: 'FI',
    phoneCode: '+358',
  },
  [IE]: {
    name: 'IE',
    code: IE,
    intlCode: 'en-IE',
    alpha2Code: 'IE',
    phoneCode: '+353',
  },
  [AT]: {
    name: 'AT',
    code: AT,
    intlCode: 'de-AT',
    alpha2Code: 'AT',
    phoneCode: '+43',
  },
  [PL]: {
    name: 'PL',
    code: PL,
    intlCode: 'en-PL',
    alpha2Code: 'PL',
    phoneCode: '+48',
  },
  [CA]: {
    name: 'CA',
    code: CA,
    intlCode: 'en-CA',
    alpha2Code: 'CA',
    phoneCode: '+1',
  },
  [IT]: {
    name: 'IT',
    code: IT,
    intlCode: 'it-IT',
    alpha2Code: 'IT',
    phoneCode: '+39',
  },
  [HU]: {
    name: 'HU',
    code: HU,
    intlCode: 'hu',
    alpha2Code: 'HU',
    phoneCode: '+36',
  },
  [NO]: {
    name: 'NO',
    code: NO,
    intlCode: 'en-NO',
    alpha2Code: 'NO',
    phoneCode: '+47',
  },
  [TR]: {
    name: 'TR',
    code: TR,
    intlCode: 'tr',
    alpha2Code: 'TR',
    phoneCode: '+90',
  },
  [JP]: {
    name: 'JP',
    code: JP,
    intlCode: 'ja',
    alpha2Code: 'JP',
    phoneCode: '',
  },
  [US]: {
    name: 'US',
    code: US,
    intlCode: 'us',
    alpha2Code: 'us',
    phoneCode: '',
  },
}

module.exports = {
  RU,
  UK,
  DE,
  SE,
  NL,
  BE,
  LU,
  DK,
  FI,
  IE,
  AT,
  PL,
  CA,
  IT,
  HU,
  NO,
  TR,
  JP,
  US,
  COUNTRIES,
  REGIONS,
  REGION_RU,
  REGION_EU,
  REGION_JP,
  REGION_US,
}
