import PropTypes from 'prop-types'
import React from 'react'

import ButtonCircleSt from 'components/ButtonCircleSt'

import {
  Inner,
  Controls,
  Button,
  Counter,
  Description,
  DescriptionText,
} from './styles'

const DESCR_POS = {
  right: 'right',
  bottom: 'bottom',
}

const buttonSizes = {
  base: 'small',
  small: 'xs',
  medium: 'newSmall',
}

/**
 * ControlAmount to increase/decrease amount of something
 * @param {object} props - component's props
 * @return {Component} Component — ControlAmount
 */
const ControlAmount = ({
  amount,
  className,
  description,
  descriptionPosition,
  isActive,
  onMinusClick,
  onPlusClick,
  isWhite,
  size,
}) => {
  const descrContent = description ? (
    <Description isBottom={descriptionPosition === DESCR_POS.bottom}>
      <DescriptionText
        {...{ isWhite }}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Description>
  ) : null
  const iconColor = isWhite ? 'white' : 'black'
  const buttonColor = isWhite ? 'altoOp1' : 'transparent'
  const buttonBlackout = isWhite ? 'transparent' : 'lilacDark'

  const buttonCircle = React.useMemo(
    () => (id, onClick) => (
      <ButtonCircleSt
        component="button"
        bg={buttonColor}
        blackout={buttonBlackout}
        icon={{
          id,
          color: iconColor,
        }}
        size={buttonSizes[size]}
        onClick={onClick}
        isDisabled={!isActive}
        linkProps={{
          'aria-label': id === 'btn_minus' ? 'minus' : 'plus',
        }}
      />
    ),
    [buttonColor, buttonBlackout, iconColor, isActive, size],
  )

  return (
    <div className={className}>
      <Controls>
        <Inner>
          <Button>{buttonCircle('btn_minus', onMinusClick)}</Button>
          <Counter as="div" {...{ isWhite, size }}>
            {amount}
          </Counter>
          <Button>{buttonCircle('btn_plus', onPlusClick)}</Button>

          {descriptionPosition === DESCR_POS.right ? descrContent : null}
        </Inner>

        {descriptionPosition === DESCR_POS.bottom ? descrContent : null}
      </Controls>
    </div>
  )
}

ControlAmount.propTypes = {
  /** Amount to show inside counter */
  amount: PropTypes.number.isRequired,

  /** Minus click callback */
  onMinusClick: PropTypes.func.isRequired,

  /** Plus click callback */
  onPlusClick: PropTypes.func.isRequired,

  /** Class name of root element */
  className: PropTypes.string,

  /** Description to show near Component */
  description: PropTypes.string,

  /** Description position (look examples) */
  descriptionPosition: PropTypes.oneOf([DESCR_POS.bottom, DESCR_POS.right]),

  /** Flag to toggle active/blocked styles */
  isActive: PropTypes.bool,

  /** Flag to set white color for text and icons */
  isWhite: PropTypes.bool,

  size: PropTypes.oneOf(['small', 'base', 'medium']),
}

ControlAmount.defaultProps = {
  className: '',
  description: '',
  descriptionPosition: DESCR_POS.right,
  isActive: true,
  size: 'base',
}

export default React.memo(ControlAmount)
