import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { COLOR_GRAY_OP_10 } from 'styles/theme/colors'

import { loaderAnimation } from './animation'
import {
  Button,
  WrapperLeft,
  WrapperRight,
  ArrowLeft,
  ArrowRight,
} from './styles'

/**
 * Component of long arrow button
 * @param {object} props - properties of component
 * @return {Component} Component — ButtonArrowLong
 */
class ButtonArrowLong extends Component {
  static propTypes = {
    /** ClassName for root tag */
    className: PropTypes.string,

    /** Arrow direction */
    direction: PropTypes.oneOf(['left', 'right']),

    /** Change default color of arrow to white */
    isWhite: PropTypes.bool,

    /** Click callback */
    onClick: PropTypes.func,

    /** Index of the active slide */
    activeIndex: PropTypes.number,

    /** Loader animation duration */
    loaderDuration: PropTypes.number,

    /** Flag to activate loader */
    isWithLoader: PropTypes.bool,

    /** Flag to control status of loader */
    isLoaderActive: PropTypes.bool,
  }

  static defaultProps = {
    className: '',
    direction: 'right',
    isWhite: false,
    onClick: () => {},
  }

  constructor(props) {
    super(props)

    this.loaderPathRef = React.createRef()
    this.loaderAnimationAppear = null
  }

  componentDidMount() {
    const { loaderDuration, isLoaderActive } = this.props
    const options = {
      node: this.loaderPathRef.current,
      time: loaderDuration,
    }

    if (this.loaderPathRef.current && isLoaderActive) {
      this.loaderAnimationAppear = loaderAnimation(options)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      activeIndex,
      isWithLoader,
      isLoaderActive,
      loaderDuration,
    } = this.props
    const options = {
      node: this.loaderPathRef.current,
      time: loaderDuration,
    }

    if (
      activeIndex !== prevProps.activeIndex &&
      isWithLoader &&
      this.loaderAnimationAppear
    ) {
      this.loaderAnimationAppear.restart()
    }

    if (isLoaderActive !== prevProps.isLoaderActive) {
      if (isLoaderActive) {
        this.loaderAnimationAppear = loaderAnimation(options)
      } else if (this.loaderAnimationAppear) {
        this.loaderAnimationAppear.time(0)
        this.loaderAnimationAppear.kill()
      }
    }
  }

  componentWillUnmount() {
    if (this.loaderAnimationAppear) {
      this.loaderAnimationAppear.kill()
    }
  }

  render() {
    const { className, isWhite, onClick, direction, isWithLoader } = this.props
    const iconColor = isWhite ? '#ffffff' : '#000000'
    const Wrapper = direction === 'left' ? WrapperLeft : WrapperRight
    const Arrow = direction === 'left' ? ArrowLeft : ArrowRight
    const pathAttrs = isWithLoader
      ? {
          strokeDashoffset: 160,
          strokeDasharray: 80,
          ref: this.loaderPathRef,
        }
      : {}
    const arrows = {
      left: (
        <svg className="d-block" viewBox="0 0 82 12" width="100%" height="100%">
          <path
            d="M6.15.75L.88 6.02l5.27 5.27M82 6.04H1.5"
            stroke={iconColor}
            fill="none"
            strokeWidth="2"
          />
        </svg>
      ),
      right: (
        <svg className="d-block" viewBox="0 0 82 12" width="100%" height="100%">
          {isWithLoader && (
            <path
              d="M0 5.96h77"
              stroke={COLOR_GRAY_OP_10}
              fill="none"
              strokeWidth="2"
            />
          )}
          <path
            d="M0 5.96h80.5"
            stroke={iconColor}
            fill="none"
            strokeWidth="2"
            {...pathAttrs}
          />
          <path
            d="M75.85 11.25l5.27-5.27L75.85.71"
            stroke={iconColor}
            fill="none"
            strokeWidth="2"
          />
        </svg>
      ),
    }

    return (
      <Button
        onClick={onClick}
        className={className}
        aria-label={direction === 'left' ? 'previous' : 'next'}
      >
        <Wrapper>
          <Arrow>{arrows[direction]}</Arrow>
        </Wrapper>
      </Button>
    )
  }
}

export default ButtonArrowLong
