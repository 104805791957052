import PropTypes from 'prop-types'
import React from 'react'

import 'static/icons/index.js'

import './Icon.scss'

/**
 * Component of Icon to show icons from public/icons
 * @return svg element
 */

const Icon = ({ id, color, strokeWidth, className, wrapperRef }) => {
  return (
    <svg className={`icon icon--${color} ${className}`} ref={wrapperRef}>
      <use xlinkHref={`#${id}`} strokeWidth={strokeWidth} />
    </svg>
  )
}

Icon.propTypes = {
  /** Id of icon (name of svg-file) */
  id: PropTypes.string.isRequired,

  /** ClassName for svg tag */
  className: PropTypes.string,

  /** Color of icon */
  color: PropTypes.oneOf(['transparent', 'white', 'black', 'gray', 'blackOp5']),

  /** Thickness of icon's border; in many cases: '1', '1.2', '2', '6'*/
  strokeWidth: PropTypes.string,

  /** You can pass ref to wrapper */
  wrapperRef: PropTypes.object,
}
Icon.defaultProps = {
  id: '',
  className: '',
  color: 'transparent',
  strokeWidth: '1.2',
  wrapperRef: null,
}

export default Icon
