import { em, math } from 'polished'
import styled from 'styled-components'

import { BoxBase } from 'styles/components/layout'

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: stretch;
  min-height: ${p => p.theme.blocks.heightBlock1};
  overflow: hidden;
  padding-top: ${p => p.theme.blocks.menuHeight};

  ${p => p.theme.mq.upMd} {
    min-height: ${p => p.theme.blocks.heightBlock1Md};
    padding-top: ${({ theme, withExtraIndent, indentHeight }) =>
      withExtraIndent
        ? indentHeight?.md
          ? math(`${em(indentHeight.md)} + ${theme.blocks.menuHeightMd}`)
          : theme.blocks.menuHeightX2Md
        : theme.blocks.menuHeightMd};
  }

  ${p => p.theme.mq.upLg} {
    min-height: ${p => p.theme.blocks.heightBlock1Lg};
    padding-top: ${({ theme, withExtraIndent, indentHeight }) =>
      withExtraIndent
        ? indentHeight?.lg
          ? math(`${em(indentHeight.lg)} + ${theme.blocks.menuHeightMd}`)
          : theme.blocks.menuHeightX2Md
        : theme.blocks.menuHeightMd};
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const BoxBaseStyled = styled(BoxBase)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`
