import PropTypes from 'prop-types'
import React from 'react'

import ButtonRoundedBase from 'components/ButtonRoundedBase'

/**
 * Dummy component of Button with rounded corners
 * @param {object} props — properties of button
 * @return {function} Function — ButtonRounded
 */
const ButtonRounded = props => {
  return <ButtonRoundedBase {...props} />
}

ButtonRounded.propTypes = {
  /** False or type of appearance animation */
  animation: PropTypes.oneOf([false, 'left', 'center']),

  /** Customize delay for appearance animation */
  animationDelay: PropTypes.number,

  /** Kind of wrapper component */
  as: PropTypes.oneOf(['a', 'button', 'Link']),

  /** String for client-routing for Link component */
  asPath: PropTypes.string,

  /** ClassName with color for background tag */
  bgClassName: PropTypes.string,

  /** ClassName with color for blackout tag */
  blackoutClassName: PropTypes.string,

  /** Title of button */
  children: PropTypes.node,

  /** Id of icon — [look Icon component](#!/Icon) */
  icon: PropTypes.string,

  /** Color of Icon. When hover it will become white */
  iconColor: PropTypes.string,

  /** Thickness of Icon's border */
  iconStrokeWidth: PropTypes.string,

  /** Flag to control disable state */
  isDisabled: PropTypes.bool,

  /** Size of component */
  size: PropTypes.oneOf(['fit', 'slim', 'base', 'large']),

  /** ClassName for text tag */
  textClassName: PropTypes.string,

  /** Use this prop for 'Link' and 'a'  */
  to: PropTypes.string,

  /** Flag to set 2px margin-top on mobile */
  withTopMarginOnMobile: PropTypes.bool,

  /** Extra props for wrapper component */
  wrapperProps: PropTypes.object,
}

ButtonRounded.defaultProps = {
  children: '',
  as: 'a',
  to: '',
  size: 'base',
  bgClassName: 'bg--purple',
  blackoutClassName: 'bg--black--op-07',
  textClassName: 'text--white',
  animation: false,
  animationDelay: 0,
  wrapperProps: {},
  iconColor: 'white',
  iconStrokeWidth: '1.2',
  isDisabled: false,
  withTopMarginOnMobile: false,
}

export default ButtonRounded
