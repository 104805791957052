import { em } from 'polished'
import styled, { keyframes } from 'styled-components'

import { unstyleButton } from 'styles/mixins'

const move = keyframes`
  0% {
      transform: translateY(-20%);
  }
    100% {
      transform: translateY(20%);
  }
`

export const Wrapper = styled.div`
  text-align: center;
`

export const Button = styled.button`
  ${unstyleButton};
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
`

export const TextBox = styled.div`
  position: relative;
  display: inline-block;
  pointer-events: none;
  max-width: ${em(250)};
  color: ${p => (p.isLight ? p.theme.colors.white : p.theme.colors.black)};
`

export const Arrow = styled.div`
  width: ${p => p.theme.arrows.arrowDownSize};
  height: ${p => p.theme.arrows.arrowDownSize};
  margin: 0 auto;
  pointer-events: none;
  animation: ${move} 1.2s ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
`
