import { em } from 'polished'
import styled, { css } from 'styled-components'

export const CostWrapper = styled.span`
  display: inline-block;
  position: relative;
  margin-bottom: 0;
  line-height: 0;

  ${p =>
    p.isWhite &&
    css`
      color: ${p => p.theme.colors.white};
      opacity: 1;
    `}

  ${p =>
    p.isStrikethrough &&
    css`
      &:after {
        content: '';
        position: absolute;
        display: block;
        left: ${em(-2)};
        right: ${em(-2)};
        top: 0;
        bottom: 0;
        height: 1px;
        margin: auto;
        transform: translateY(2px);
        background: ${({
          theme: {
            colors: { white, bodyColor },
          },
        }) => (p.isWhite ? white : bodyColor)};
      }

      ${p => p.theme.mq.upMd} {
        &:after {
          transform: ${p =>
            p.size === 'base' || p.size === 'small'
              ? 'translateY(2px)'
              : 'translateY(3px)'};
          height: ${p =>
            p.size === 'base' || p.size === 'small' ? '1px' : '2px'};
        }
      }
    `}
`
