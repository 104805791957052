// We are using this constants inside webpack config
// therefore you have to forget about es6
// We write it in lower case to distinguish deploy regions from locales

const REGION_RU = 'region_ru'
const REGION_EU = 'region_eu'
const REGION_JP = 'region_jp'
const REGION_US = 'region_us'

module.exports = {
  REGION_RU,
  REGION_EU,
  REGION_JP,
  REGION_US,
}
