import { merge } from 'lodash'

import {
  CART_INITED,
  CART_UPDATE,
  CART_PROMO_SEND,
  CART_PROMO_RESET,
  CART_PROMO_SUCCESS,
  CART_PROMO_FAILURE,
  CART_COLLECT_RESET,
  CART_COMMON_UPDATE_REQUEST,
  CART_COMMON_UPDATE_SUCCESS,
  CART_COMMON_UPDATE_FAILURE,
  CART_PERSONAL_INFO_SAVE,
  CART_DELIVERY_TYPE_SAVE,
  CART_UNFINISHED_ID_SAVE,
  CART_CLEAR_GOODS,
  CART_RESET,
  SAVE_ORDER_ID,
  CART_RUSSIA_COLLECT_REQUEST,
  CHANGE_LOCALE,
} from 'constants/actionTypes'
import { REGION_RU } from 'constants/regions'
import { GOODS_TYPES } from 'constants/sales'
import { getFormErrors } from 'utils/errors'

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CART_INITED]: (state, { payload: { userGA } }) => {
    return {
      ...state,
      userGA,
      isInitialized: true,
    }
  },
  [CART_UPDATE]: (state, { payload }) => {
    const newState = { ...state }

    if (payload.goods) {
      newState.goods = merge({}, newState.goods, payload.goods)
    }
    if (payload.promo) {
      newState.promo = { ...newState.promo, ...payload.promo }
    }
    if (payload.sum) {
      newState.sum = { ...newState.sum, ...payload.sum }
    }
    if (payload.personal) {
      newState.personal = { ...newState.personal, ...payload.personal }
    }
    if (payload.deliveryType) {
      newState.deliveryType = payload.deliveryType
    }
    if (payload.deliveryAvailableTypes) {
      newState.deliveryAvailableTypes = payload.deliveryAvailableTypes
    }

    return newState
  },
  [CHANGE_LOCALE]: state => {
    return {
      ...state,
      personal: {},
    }
  },
  [CART_PROMO_RESET]: state => {
    return {
      ...state,
      promo: {
        ...initialState.promo,
      },
    }
  },
  [CART_PROMO_SEND]: (state, { payload }) => {
    const { promocode } = payload

    return {
      ...state,
      promo: {
        ...initialState.promo,
        name: promocode,
        isFetching: true,
        errors: getFormErrors({}),
      },
    }
  },
  [CART_PROMO_SUCCESS]: (state, { payload }) => {
    const { id, name } = payload

    return {
      ...state,
      promo: {
        ...state.promo,
        id,
        name,
        isFetching: false,
      },
    }
  },
  [CART_PROMO_FAILURE]: (state, { payload }) => {
    const { errors } = payload

    return {
      ...state,
      promo: {
        ...state.promo,
        isFetching: false,
        errors,
      },
    }
  },
  [CART_PERSONAL_INFO_SAVE]: (state, { payload }) => {
    const { personalInfo } = payload
    const nextPersonal = {
      ...state.personal,
      ...personalInfo,
    }

    return {
      ...state,
      personal: nextPersonal,
    }
  },
  [CART_DELIVERY_TYPE_SAVE]: (state, { payload }) => {
    return {
      ...state,
      deliveryType: payload,
    }
  },
  [CART_UNFINISHED_ID_SAVE]: (state, { payload }) => {
    const { id } = payload

    return {
      ...state,
      orderIdUnfinished: id,
    }
  },

  [CART_RUSSIA_COLLECT_REQUEST]: state => {
    return merge({}, state, {
      isOrderCollected: true,
    })
  },

  [CART_COLLECT_RESET]: state => {
    return merge({}, state, {
      orderId: null,
      clientSecret: null,
      isOrderCollected: false,
    })
  },

  [CART_COMMON_UPDATE_REQUEST]: state => {
    return merge({}, state, {
      isFetching: true,
      error: null,
      errorIsUserFriendly: false,
    })
  },
  [CART_COMMON_UPDATE_FAILURE]: (state, { payload }) => {
    const { error, errorCode = '', errorIsUserFriendly = false } = payload

    return merge({}, state, {
      isFetching: false,
      error,
      errorCode,
      errorIsUserFriendly,
    })
  },
  [CART_COMMON_UPDATE_SUCCESS]: (state, { payload }) => {
    return merge({}, state, {
      ...payload,
      isFetching: false,
    })
  },

  [CART_CLEAR_GOODS]: state => {
    return {
      ...initialState,
      isInitialized: true,
      orderId: state.orderId,
    }
  },
  [CART_RESET]: state => {
    return {
      ...initialState,
      isInitialized: true,
      userGA: state.userGA,
    }
  },
  [SAVE_ORDER_ID]: (state, { payload }) => {
    const { orderId, error } = payload

    return {
      ...state,
      orderId,
      error,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  userGA: null,
  isInitialized: false,
  isFetching: false,
  isOrderCollected: false,
  isOrderSucceed: null,
  orderId: null,
  redirectUrl: null,
  orderIdUnfinished: null,
  clientSecret: null,
  error: null,
  errorIsUserFriendly: false,
  isSinglePage: process.env.region === REGION_RU,
  goods: {
    [GOODS_TYPES.dna]: {
      amount: 0,
      cost: 0,
      realCost: 0,
      isSubscription: false,
    },
    [GOODS_TYPES.biome]: {
      amount: 0,
      cost: 0,
      realCost: 0,
      isSubscription: true,
    },
    [GOODS_TYPES.wgs]: {
      amount: 0,
      cost: 0,
      realCost: 0,
      isSubscription: false,
    },
  },
  promo: {
    isFetching: false,
    errors: getFormErrors({}),
    name: '',
    id: null,
  },
  sum: {
    amount: 0,
    cost: 0,
    realCost: 0,
    diff: 0,
    delivery: 0,
    isDeliveryFree: false,
  },
  personal: {},
  deliveryType: null,
  deliveryAvailableTypes: null,
}

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
