import styled from 'styled-components'

import {
  h1,
  h1Md,
  h1MdSmall,
  h2,
  h2Md,
  h3,
  h3Md,
  h4,
  h4Md,
  textBase,
  textBaseMd,
  textBold,
  textMedium,
  footerBase,
  footerBaseMd,
  textBoldMd,
  priceSmall,
  priceBaseBold,
  priceMedium,
  priceMediumMd,
  priceBig,
  priceBigMd,
  textBaseXs,
  textCartSubtitle,
  cartBase,
  faqH2,
  faqH2Md,
  textDisclaimer,
  textDisclaimerMd,
  textCartSubtitleMd,
  cartItemTitle,
  cartItemTitleMd,
} from 'styles/mixins_styled/typography'

// h1
export const H1 = styled.h1`
  ${h1};

  ${p => p.theme.mq.upMd} {
    ${h1Md};
  }
`

export const H1Small = styled.h1`
  ${h2};

  ${p => p.theme.mq.upMd} {
    ${h1MdSmall};
  }
`

// h2
export const H2 = styled.h2`
  ${h2};

  ${p => p.theme.mq.upMd} {
    ${h2Md};
  }
`
export const H2Like = styled.span`
  ${h2};
  margin-bottom: 0;

  ${p => p.theme.mq.upMd} {
    ${h2Md};
    margin-bottom: 0;
  }
`

// h3
export const H3 = styled.h3`
  ${h3};

  ${p => p.theme.mq.upMd} {
    ${h3Md};
  }
`
export const H3Like = styled.span`
  ${h3};
  font-family: ${p => p.theme.typography.baseFont};
  margin-bottom: 0;
`

export const H4 = styled.h4`
  ${h4}

  ${p => p.theme.mq.upMd} {
    ${h4Md};
  }
`

// base text
export const Text = styled.p`
  ${textBase};

  ${p => p.theme.mq.upMd} {
    ${textBaseMd};
  }
`

export const TextSmall = styled.p`
  ${textBase};
`

// base text bold
export const Bold = styled.span`
  ${textBold};

  ${p => p.theme.mq.upMd} {
    ${textBoldMd};
  }
`

// base text without line height
export const TextLH = styled.span`
  ${textBase};
  line-height: inherit;

  ${p => p.theme.mq.upMd} {
    ${textBaseMd};
    line-height: inherit;
  }
`

export const TextMedium = styled.span`
  ${textMedium};

  ${p => p.theme.mq.upMd} {
    font-size: ${p => p.theme.typography.textMediumMd};
  }
`

export const TextLHXs = styled.span`
  ${textBaseXs};

  ${p => p.theme.mq.upMd} {
    ${textBaseMd};
    line-height: inherit;
  }
`

export const TextCartSubtitle = styled.span`
  ${textCartSubtitle};

  ${p => p.theme.mq.upMd} {
    ${textCartSubtitleMd};
  }
`

// price small
export const PriceSmall = styled.span`
  ${priceSmall};
`

// price base bold
export const PriceBaseBold = styled.span`
  ${priceBaseBold};
`

// price medium
export const PriceMedium = styled.span`
  ${priceMedium};

  ${p => p.theme.mq.upMd} {
    ${priceMediumMd};
  }
`

export const CartBig = styled.span`
  ${priceMediumMd};

  ${p => p.theme.mq.upMd} {
    ${priceBig};
  }
`

export const CartItemTitle = styled.h5`
  ${cartItemTitle};

  ${p => p.theme.mq.upMd} {
    ${cartItemTitleMd};
  }
`

export const CartEmptyPrice = styled.h5`
  ${cartItemTitle};

  ${p => p.theme.mq.upMd} {
    ${cartItemTitleMd};
    font-size: ${p => p.theme.typography.h3};
    line-height: ${p => p.theme.typography.h3Lh};
  }
`

// price big
export const PriceBig = styled.span`
  ${priceBig};

  ${p => p.theme.mq.upMd} {
    ${priceBigMd};
  }
`

// cart text
export const CartText = styled.span`
  ${cartBase};
`

export const FaqTitle = styled.h2`
  ${faqH2};

  ${p => p.theme.mq.upMd} {
    ${faqH2Md};
  }
`

// disclaimer text
export const TextDisclaimer = styled.p`
  ${textDisclaimer};

  ${p => p.theme.mq.upMd} {
    ${textDisclaimerMd};
  }
`

// footer text
export const FooterText = styled.p`
  ${footerBase};
  margin-bottom: 0;

  a {
    color: ${p => p.isWhite ? 'var(--color-W100)' : p.isPurple ? 'var(--color-W30)' : 'var(--color-D100)'}
    text-decoration: underline;
  }

  ${p => p.theme.mq.upMd} {
    ${footerBaseMd};
  }
`

// Link
export const A = styled.a`
  transition: ${p => p.theme.animation.linkTransition};
  opacity: ${p => (p.isInverted ? p.theme.typography.linkHoveredOp : 1)};

  ${p => p.theme.mq.hover} {
    &:hover {
      opacity: ${p => (p.isInverted ? 1 : p.theme.typography.linkHoveredOp)};
    }
  }
`

export const TextConsultation = styled.p`
  font-family: ${p =>
    p.isBold ? p.theme.typography.mediumFont : p.theme.typography.baseFont};
  line-height: ${p => p.theme.typography.h2LhMd};

  ${p => p.theme.mq.upMd} {
    font-size: ${p => p.theme.typography.h3};
  }
`

export const TextSmallDescr = styled.span`
  font-size: ${p => p.theme.typography.textSmallDescr};
  line-height: ${p => p.theme.typography.textSmallDescrLh};
`

export const TextCartDescr = styled.span`
  font-size: ${p => p.theme.typography.textSmallDescr};

  ${p => p.theme.mq.upMd} {
    font-size: ${p => p.theme.typography.textSmallDescrMd};
  }
`

export const TextUpper = styled.span`
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.textUppercase};
  text-transform: uppercase;
  letter-spacing: 0.15em;
`

// Custom text for bottom black popup
export const TextCookies = styled.p`
  font-family: ${p => p.theme.typography.baseFont};
  font-size: ${p => p.theme.typography.cookieSmall};
  line-height: ${p => p.theme.typography.cookieSmallLh};
  font-weight: 400;

  ${p => p.theme.mq.upMd} {
    font-size: ${p => p.theme.typography.cartFontSize};
    line-height: ${p => p.theme.typography.baseLhXs};
  }

  a {
    ${A};
  }
`
