import { em } from 'polished'
import styled, { css } from 'styled-components'

import { Text, TextSmallDescr } from 'styles/components/typography'
import { japaneseBehaviour } from 'styles/mixins_styled/typography'

export const Controls = styled.div`
  display: inline-block;
`

export const Inner = styled.div`
  display: flex;
  align-items: center;
`

export const Button = styled.div`
  flex: 1 0 auto;
`

export const Counter = styled(Text)`
  padding: 0 ${em(15)};
  color: ${p => (p.isWhite ? p.theme.colors.white : p.theme.colors.black)};

  ${p =>
    p.size === 'small' &&
    css`
      display: block;
      font-size: ${p => p.theme.typography.textUppercase};
      line-height: 1.26;

      ${p => p.theme.mq.upMd} {
        font-size: ${p => p.theme.typography.fontSize};
        line-height: ${p => p.theme.typography.cartFontLh};
      }
    `}
`

export const Description = styled.div`
  line-height: 0;

  ${({ isBottom }) =>
    isBottom &&
    css`
      margin-top: ${em(10)};
      text-align: center;
    `}

  ${({ isBottom }) =>
    !isBottom &&
    css`
      margin-left: ${em(15)};
      max-width: ${em(75)};
    `}
`

export const DescriptionText = styled(TextSmallDescr)`
  ${japaneseBehaviour};

  ${({ theme }) => theme.mq.upMd} {
    font-size: 18px;
    line-height: 26px;
  }

  color: ${p =>
    p.isWhite ? p.theme.colors.whiteOp5 : p.theme.colors.blackOp4};
`
