import PropTypes from 'prop-types'
import React from 'react'

import { Box, BoxBaseStyled, Inner, Overlay, Wrapper } from './styles'

/**
 * Base Component to wrap sections of website's page
 * @param {object} props - props of component
 * @return {Component} Dumb Component — SectionWrapper
 */
const SectionWrapper = React.forwardRef(
  (
    {
      classNameWrapper,
      classNameInner,
      className,
      children,
      withOverlay,
      withIndents,
      beforeElement,
      isOverflowHidden,
      isWideToDesktop,
    },
    ref,
  ) => {
    return (
      <Wrapper
        {...{ ref, isOverflowHidden, withIndents }}
        className={classNameWrapper}
      >
        {beforeElement}
        <Box as={withIndents ? Box : BoxBaseStyled} className={className}>
          {withOverlay ? <Overlay /> : null}
          <Inner isWideToDesktop={isWideToDesktop} className={classNameInner}>
            {children}
          </Inner>
        </Box>
      </Wrapper>
    )
  },
)

SectionWrapper.propTypes = {
  /** Inner section content */
  children: PropTypes.node.isRequired,

  /** You can pass here an element to render it before section
   * (for example: to fill whole area with image or some media)*/
  beforeElement: PropTypes.element,

  /** Classes for inner div element */
  className: PropTypes.string,

  /** Classes for inner div element */
  classNameInner: PropTypes.string,

  /** Classes for wrapper div element */
  classNameWrapper: PropTypes.string,

  /** Do we need to hide overflow */
  isOverflowHidden: PropTypes.bool,

  /** Do we need fill whole width on mobile and tablet */
  isWideToDesktop: PropTypes.bool,

  /** Flag to add/remove indents around wrapper */
  withIndents: PropTypes.bool,

  /** Flag to add/remove absolute overlay for bg */
  withOverlay: PropTypes.bool,
}

SectionWrapper.defaultProps = {
  className: '',
  classNameInner: '',
  classNameWrapper: '',
  beforeElement: null,
  isOverflowHidden: false,
  isWideToDesktop: false,
  withOverlay: false,
  withIndents: false,
}

export default SectionWrapper
