import { initialState } from 'reducers/cartReducer'

export const getCurrentLocale = state => state.localization.locale
export const getCurrentLanguage = state => state.localization.language
export const getSales = state => state.localization.sales
export const getCurrentPage = (state, page) => state.localization.pages[page]
export const getIsCartInitialized = state => state.cart.isInitialized
export const getIsCartInitializingOrUpdating = state =>
  !getIsCartInitialized(state) || state.localization.sales.isFetching
export const getCartState = state => state.cart
export const getCartErrorInfo = ({
  cart: { error, errorCode, errorIsUserFriendly },
}) => ({
  error,
  errorCode,
  errorIsUserFriendly,
})
export const getCartStateWithFilteredGoods = state => {
  const { cart } = state
  const {
    sales: {
      goods: {
        biome: { isActive: isBiomeActive },
        dna: { isActive: isDnaActive },
      },
    },
  } = state.localization

  if (!isDnaActive) {
    return {
      ...cart,
      goods: {
        ...cart.goods,
        dna: initialState.goods.dna,
      },
    }
  } else if (!isBiomeActive) {
    return {
      ...cart,
      goods: {
        ...cart.goods,
        biome: initialState.goods.biome,
      },
    }
  }

  return cart
}
