import { em } from 'polished'
import styled, { css } from 'styled-components'

const getStroke = (bg, { colors }) => {
  switch (bg) {
    case 'transparent':
      return css`
        stroke: ${colors.blackOp1};
        stroke-width: 1;
      `
    default:
      return css`
        stroke: none;
        stroke-width: 0;
      `
  }
}

const transition = css`
  transition-duration: ${p => p.theme.animation.timeBtns};
  transition-timing-function: ${p => p.theme.animation.cubicBezier};
`

export const Box = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;
  pointer-events: ${p => (p.isHoverable && !p.isDisabled ? 'all' : 'none')};
`

export const Substrate = styled.svg`
  position: absolute;
  left: 0;
  top: 0;
  overflow: visible;
`

const Circle = styled.circle`
  ${transition};
  transform-origin: center;
  transform: scale(1);
`

const BackgroundCircleHover = css`
  stroke: transparent;
  transform: ${p =>
    `scale(${p.theme.buttons.buttonCircleGrowingCoef *
      0.98})`}; // hack to hide halo around blackout
`
export const BackgroundCircle = styled(Circle)`
  transition-property: opacity, transform, stroke, fill;
  fill: ${p => p.theme.colors[p.bg]};
  ${p => getStroke(p.bg, p.theme)};

  ${p => p.isHighlight && BackgroundCircleHover}

  ${p => p.theme.mq.hover} {
    ${Box}:hover & {
      ${BackgroundCircleHover}
    }
  }
`

const BlackOutCircleHover = css`
  opacity: 1;
  transform: ${p => `scale(${p.theme.buttons.buttonCircleGrowingCoef})`};
`
export const BlackOutCircle = styled(Circle)`
  transition-property: opacity, transform;
  opacity: 0;
  stroke: none;
  fill: ${p => p.theme.colors[p.blackout]};

  ${p => p.isHighlight && BlackOutCircleHover}
  ${p => p.theme.mq.hover} {
    ${Box}:hover & {
      ${BlackOutCircleHover}
    }
  }
`

const ButtonInnerHover = css`
  color: ${p => p.hoverColor};

  svg {
    fill: ${p => p.hoverColor} !important;
    stroke: ${p => p.hoverColor} !important;
  }
`
export const ButtonInner = styled.div`
  ${transition};
  position: relative;
  display: flex;
  color: ${p => p.color};
  width: ${p => em(p.width)};
  height: ${p => em(p.height)};
  align-items: center;
  justify-content: center;
  text-align: center;
  transition-property: color;
  opacity: ${p => (p.isDisabled ? 0.4 : 1)};

  ${p =>
    p.withIcon &&
    css`
      padding: ${p.theme.buttons.indent[p.size]};
    `}

  ${p => p.isHighlight && ButtonInnerHover}
  ${p => p.theme.mq.hover} {
    ${Box}:hover & {
      ${ButtonInnerHover}
    }
  }
`
