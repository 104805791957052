import PropTypes from 'prop-types'
import React from 'react'

import Cost from 'components/Cost'

import { SaleWrapper, SaleCost } from './styles'

/**
 * Component with original and stock prices
 * @return {CostsStockAndBase} Component - CostsStockAndBase
 */
const CostsStockAndBase = ({
  cost,
  realCost,
  currency,
  intlCode,
  isFetching,
  isWhite,
  isCenter,
  isRight,
  isMobileCenter,
  isTabNotCenter,
  isTransparent,
  size,
  crossedSize,
  className,
  position,
}) => {
  const baseCostSize = crossedSize || (size === 'big' ? 'h2' : 'base')

  if (isFetching || !cost) {
    return null
  }

  return realCost && realCost !== cost ? (
    <SaleWrapper className={className}>
      <SaleCost
        {...{
          isTransparent,
          isWhite,
          isCenter,
          isRight,
          isMobileCenter,
          isTabNotCenter,
          position,
        }}
      >
        <Cost
          {...{
            value: realCost,
            size: baseCostSize,
            strikethrough: true,
            intlCode,
            currency,
            isWhite,
          }}
        />
      </SaleCost>
      <Cost
        {...{
          value: cost,
          intlCode,
          currency,
          isWhite,
          size,
        }}
      />
    </SaleWrapper>
  ) : (
    <div className={className}>
      <Cost
        {...{
          value: cost,
          intlCode,
          currency,
          isWhite,
          size,
        }}
      />
    </div>
  )
}

CostsStockAndBase.propTypes = {
  /** Code of needed currency */
  currency: PropTypes.string.isRequired,

  /** Intl code to format cost */
  intlCode: PropTypes.string.isRequired,

  /** Cost of product */
  cost: PropTypes.number,

  /** Flag to control text align */
  isCenter: PropTypes.bool,

  /** Flag to control text align */
  isRight: PropTypes.bool,

  /** Control activity of discount */
  isFetching: PropTypes.bool,

  /** Flag to control text align on mobile only */
  isMobileCenter: PropTypes.bool,

  /** Flag to control text align: mobile and desktop - center, tablet - left */
  isTabNotCenter: PropTypes.bool,

  /** Flag to define color theme */
  isWhite: PropTypes.bool,

  /** Is crossed cost transparent */
  isTransparent: PropTypes.bool,

  /** Real cost of product (if discount is active) */
  realCost: PropTypes.number,

  /** Size of cost */
  size: PropTypes.string,

  /** Size of crossed cost */
  crossedSize: PropTypes.string,

  /** Extra class names */
  className: PropTypes.string,

  /** Position of crossed cost **/
  position: PropTypes.string,
}

CostsStockAndBase.defaultProps = {
  size: 'h2',
  crossedSize: '',
  className: '',
  position: 'top',
}

export default CostsStockAndBase
