export const PAGE_REQUEST = 'PAGE_REQUEST'
export const PAGE_SUCCESS = 'PAGE_SUCCESS'
export const PAGE_FAILURE = 'PAGE_FAILURE'
export const CHANGE_LOCALE = 'CHANGE_LOCALE'

// CART
export const CART_INIT = 'CART_INIT'
export const CART_INITED = 'CART_INITED'

export const CART_ADD_AMOUNT = 'CART_ADD_AMOUNT'
export const CART_PROMO_SEND = 'CART_PROMO_SEND'
export const CART_PROMO_RESET = 'CART_PROMO_RESET'
export const CART_PROMO_SUCCESS = 'CART_PROMO_SUCCESS'
export const CART_PROMO_FAILURE = 'CART_PROMO_FAILURE'
export const CART_CALCULATE = 'CART_CALCULATE'
export const CART_UNFINISHED_ID_SAVE = 'CART_UNFINISHED_ID_SAVE'

// action to update amount of goods, prices and other options of cart after calculation
export const CART_UPDATE = 'CART_UPDATE'

// update personal info and store some on servers (unfinished orders mechanism)
export const CART_PERSONAL_INFO_CHANGE = 'CART_PERSONAL_INFO_CHANGE'
export const CART_PERSONAL_INFO_SAVE = 'CART_PERSONAL_INFO_SAVE'

// action to initialize first step of Stripe order — collect data
export const CART_EUROPE_COLLECT_REQUEST = 'CART_EUROPE_COLLECT_REQUEST'
export const CART_COLLECT_RESET = 'CART_COLLECT_RESET'

// action to initialize second step of Stripe order — payment
export const CART_EUROPE_PAYMENT_REQUEST = 'CART_EUROPE_PAYMENT_REQUEST'

// bunch of actions to work with async cart updates
export const CART_COMMON_UPDATE_REQUEST = 'CART_COMMON_UPDATE_REQUEST'
export const CART_COMMON_UPDATE_FAILURE = 'CART_COMMON_UPDATE_FAILURE'
export const CART_COMMON_UPDATE_SUCCESS = 'CART_COMMON_UPDATE_SUCCESS'

// actions to deal with delivery type changes
export const CART_DELIVERY_TYPE_CHANGE = 'CART_DELIVERY_TYPE_CHANGE'
export const CART_DELIVERY_TYPE_SAVE = 'CART_DELIVERY_TYPE_SAVE'

// action to initialize first step of Stripe order — collect data
export const CART_RUSSIA_COLLECT_REQUEST = 'CART_RUSSIA_COLLECT_REQUEST'
export const CART_RUSSIA_PAYMENT_REQUEST = 'CART_RUSSIA_PAYMENT_REQUEST'

// action when user leaving cart page
export const CART_PAGE_LEAVING = 'CART_PAGE_LEAVING'

export const CART_CLEAR_GOODS = 'CART_CLEAR_GOODS'
export const CART_RESET = 'CART_RESET'

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST'
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS'
export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE'

export const SALES_INFO_REQUEST = 'SALES_INFO_REQUEST'
export const SALES_INFO_SUCCESS = 'SALES_INFO_SUCCESS'
export const SALES_INFO_FAILURE = 'SALES_INFO_FAILURE'

export const SAVE_ORDER_ID = 'SAVE_ORDER_ID'

export const DELIVERY_ZONE_REQUEST = 'DELIVERY_ZONE_REQUEST'
export const DELIVERY_ZONE_SUCCESS = 'DELIVERY_ZONE_SUCCESS'
export const DELIVERY_ZONE_FAILURE = 'DELIVERY_ZONE_FAILURE'
