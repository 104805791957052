/**
 * Created by Edward on 15/05/2017.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'

import { Text } from 'styles/components/typography'

import './SliderClassicCounter.scss'

const SliderClassicCounter = ({ curIndex, amount, className }) => {
  return (
    <div className={`sc-counter ${className}`}>
      <span className="sc-counter__wrapper">
        <TransitionGroup component={null}>
          <Transition timeout={300} key={`counter.${curIndex}`}>
            {status => (
              <Text
                as="span"
                className={`sc-counter__index sc-counter__index--${status}`}
              >
                {curIndex}
              </Text>
            )}
          </Transition>
        </TransitionGroup>
      </span>
      <Text as="span">
        &nbsp;/&nbsp;
        {amount}
      </Text>
    </div>
  )
}

SliderClassicCounter.propTypes = {
  amount: PropTypes.number,
  className: PropTypes.string,
  curIndex: PropTypes.number,
}

SliderClassicCounter.defaultProps = {
  curIndex: 0,
  amount: 0,
  className: '',
}

export default SliderClassicCounter
