import { css } from 'styled-components'

export const unstyleButton = css`
  background: transparent;
  border: none;
  padding: 0;
  outline: none !important;
  &:focus,
  &.active {
    outline: 0 !important;
  }
`

export const linkInverted = css`
  transition: opacity ${p => p.theme.animation.timeBtns};
  cursor: pointer;
  opacity: ${p => p.theme.typography.linkHoveredOp};

  ${p => p.theme.mq.hover} {
    &:hover,
    &.active {
      opacity: 1;
    }
  }
`

export const inputWrapper = css`
  display: block;
  position: relative;
  margin: ${p => p.theme.typography.fontSizeXs} 0
    ${p => p.theme.indents.inputBottomIndent};
`
