import { math } from 'polished'
import { css } from 'styled-components'

export const getRowConfig = ({
  indent: { xs = 20, md = 40, lg = 40 } = {},
}) => ({
  xs,
  md,
  lg,
})

export const makeXs0 = css`
  padding-left: ${p => math(`${p.theme.indents.baseIndentXs}/2`)};
  padding-right: ${p => math(`${p.theme.indents.baseIndentXs}/2`)};
  margin-left: -${p => p.theme.indents.baseIndentXs};
  margin-right: -${p => p.theme.indents.baseIndentXs};
`

export const makeXs20 = css`
  padding-left: ${p => math(`${p.theme.indents.baseIndentXs}/2`)};
  padding-right: ${p => math(`${p.theme.indents.baseIndentXs}/2`)};
`

export const makeMd0 = css`
  ${p => p.theme.mq.upMd} {
    padding-left: ${p => math(`${p.theme.indents.baseIndentMd}/2`)};
    padding-right: ${p => math(`${p.theme.indents.baseIndentMd}/2`)};
    margin-left: -${p => p.theme.indents.baseIndentMd};
    margin-right: -${p => p.theme.indents.baseIndentMd};
  }
`

export const makeMd20 = css`
  ${p => p.theme.mq.upMd} {
    padding-left: 0;
    padding-right: 0;
  }

  ${p => p.theme.mq.upLg} {
    padding-left: ${p => math(`${p.theme.indents.baseIndentMd}/2`)};
    padding-right: ${p => math(`${p.theme.indents.baseIndentMd}/2`)};
  }
`

export const makeMd40 = css`
  ${p => p.theme.mq.upMd} {
    padding-left: ${p => math(`${p.theme.indents.baseIndentMd}/2`)};
    padding-right: ${p => math(`${p.theme.indents.baseIndentMd}/2`)};
  }
`

export const makeLg0 = css`
  ${p => p.theme.mq.upLg} {
    margin-left: -${p => math(`${p.theme.indents.baseBoxIndentLg}`)};
    margin-right: -${p => math(`${p.theme.indents.baseBoxIndentLg}`)};
  }
`

export const makeLg20 = css`
  ${p => p.theme.mq.upLg} {
    padding-left: 0;
    padding-right: 0;
    margin-left: -${p => math(`${p.theme.indents.baseIndentXs}*3`)};
    margin-right: -${p => math(`${p.theme.indents.baseIndentXs}*3`)};
  }
`

export const makeLg40 = css`
  ${p => p.theme.mq.upLg} {
    margin-left: -${p => p.theme.indents.baseIndentMd};
    margin-right: -${p => p.theme.indents.baseIndentMd};
  }
`
