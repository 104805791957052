import { IT, DK } from 'constants/countries'

export const account = '/account'
export const accountSources = '/account/sources'
export const skolkovo = 'https://sk.ru'
export const italianDistributionDNA = 'https://shop.biorep.it/shop/dna-test/'
export const italianDistributionMicrobiome =
  'https://shop.biorep.it/shop/microbiome-test/'

export const externalDistributionLinks = {
  [IT]: {
    dna: 'https://shop.biorep.it/shop/dna-test/',
    biome: 'https://shop.biorep.it/shop/microbiome-test/',
  },
  [DK]: {
    dna: 'https://www.bioform.shop/sundhedstest',
    biome: 'https://www.bioform.shop/sundhedstest',
  }
}
