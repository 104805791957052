import PropTypes from 'prop-types'
import React from 'react'
import { css } from 'styled-components'

import ButtonRoundedBaseSt from 'components/ButtonRoundedBaseSt'
import Icon from 'components/Icon'
import { Text } from 'styles/components/typography'

import {
  decreaseWidthToCenterAndHideText,
  decreaseWidthToLeftAndHideText,
  decreaseWidthToRightAndHideText,
  increaseWidthToCenterAndShowText,
  increaseWidthToLeftAndShowText,
  increaseWidthToRightAndShowText,
  showAndHideSuccessIcon,
} from './animations'
import { ContentBox, IconBox, TextBox } from './styles'

const LEFT_ALIGN = 'left'
const CENTER_ALIGN = 'center'
const RIGHT_ALIGN = 'right'

/**
 * Component of Button with rounded corners
 * @param {object} props — properties of Component
 * @return {Component} Component — ButtonSuccessful
 */

const ButtonSuccessfulSt = ({
  children,
  secondClickText,
  textStyles,
  bgStyles,
  blackoutStyles,
  animAlign,
  firstClickBeforeCb,
  firstClickCb,
  secondClickCb,
  secondBtnAs,
  secondClickAsPath,
  secondClickTo,
  size,
  ...props
}) => {
  const [condition, setCondition] = React.useState(0)

  const alignedAnimation = React.useMemo(() => {
    if (animAlign === CENTER_ALIGN) {
      return {
        decrease: decreaseWidthToCenterAndHideText,
        increase: increaseWidthToCenterAndShowText,
      }
    } else if (animAlign === RIGHT_ALIGN) {
      return {
        decrease: decreaseWidthToRightAndHideText,
        increase: increaseWidthToRightAndShowText,
      }
    }

    return {
      decrease: decreaseWidthToLeftAndHideText,
      increase: increaseWidthToLeftAndShowText,
    }
  }, [animAlign])

  const onClick = React.useCallback(() => {
    switch (condition) {
      case 0: {
        if (firstClickBeforeCb && typeof firstClickBeforeCb === 'function') {
          firstClickBeforeCb()
        }

        setCondition(1)
        break
      }
      case 4: {
        if (secondClickCb && typeof secondClickCb === 'function') {
          secondClickCb()
        }
        break
      }
      default:
        break
    }
  }, [condition, firstClickBeforeCb, secondClickCb])

  const compiledBgStyles = React.useMemo(() => {
    if (condition < 2) {
      return bgStyles
    }

    return css`
      background-color: ${p => p.theme.colors.grass};
    `
  }, [condition, bgStyles])

  const secondTextStyles = React.useMemo(() => {
    return css`
      ${textStyles};

      color: ${p => p.theme.colors.white};
    `
  }, [textStyles])

  const btnProps = {
    as: 'button',
    notHoverable: true,
    bgStyles: compiledBgStyles,
    blackoutStyles,
    onClick,
    size,
    ...props,
  }

  const animProps = React.useMemo(() => {
    switch (condition) {
      case 1: {
        return {
          animTimeline: alignedAnimation.decrease,
          animTimelineCb: () => setCondition(2),
        }
      }
      case 2: {
        return {
          animTimeline: showAndHideSuccessIcon,
          animTimelineCb: () => {
            setCondition(3)

            if (firstClickCb && typeof firstClickCb === 'function') {
              firstClickCb()
            }
          },
        }
      }
      case 3: {
        return {
          animTimeline: alignedAnimation.increase,
          animTimelineCb: () => setCondition(4),
          animTimelineResetStyles: true,
        }
      }
      case 4: {
        return {
          notHoverable: false,
          as: secondBtnAs,
          to: secondClickTo,
          asPath: secondClickAsPath,
        }
      }
      default: {
        return {
          notHoverable: false,
        }
      }
    }
  }, [
    condition,
    alignedAnimation,
    firstClickCb,
    secondBtnAs,
    secondClickTo,
    secondClickAsPath,
  ])

  const longestTextIdx = React.useMemo(
    () => (children?.length > secondClickText?.length ? 0 : 2),
    [children, secondClickText],
  )

  return (
    <ButtonRoundedBaseSt {...btnProps} {...animProps}>
      <TextBox
        isActive={condition < 2}
        isLongest={!longestTextIdx}
        textStyles={textStyles}
      >
        <Text as="span" dangerouslySetInnerHTML={{ __html: children }} />
      </TextBox>
      <ContentBox isActive={condition === 2}>
        <IconBox isRightAlign={animAlign === RIGHT_ALIGN}>
          <Icon id="btn_check" color="white" />
        </IconBox>
      </ContentBox>
      <TextBox
        isActive={condition > 2}
        isLongest={longestTextIdx === 2}
        textStyles={secondTextStyles}
      >
        <Text as="span" dangerouslySetInnerHTML={{ __html: secondClickText }} />
      </TextBox>
    </ButtonRoundedBaseSt>
  )
}

ButtonSuccessfulSt.propTypes = {
  /** Title of button */
  children: PropTypes.string,

  /** Size of component */
  size: PropTypes.string,

  /** First click callback (it calls when you clicked) */
  firstClickBeforeCb: PropTypes.func,

  /** First click callback (it calls when successful arrow was appeared) */
  firstClickCb: PropTypes.func,

  /** Second click callback **/
  secondClickCb: PropTypes.func,

  /** Text in the end of animation  */
  secondClickText: PropTypes.string,

  /** It is prop almost only for styleguide case we cant use router's Link component there */
  secondBtnAs: PropTypes.oneOf(['Link', 'a']),

  /** Use this prop for 'Link' and 'a'  */
  secondClickTo: PropTypes.string,

  /** String for client-routing for Link component */
  secondClickAsPath: PropTypes.string,

  /** Styles for text tag */
  textStyles: PropTypes.array,

  /** Styles for background tag */
  bgStyles: PropTypes.array,

  /** Styles for blackout tag */
  blackoutStyles: PropTypes.array,

  /** Animation align  */
  animAlign: PropTypes.oneOf([LEFT_ALIGN, CENTER_ALIGN, RIGHT_ALIGN]),
}

ButtonSuccessfulSt.defaultProps = {
  secondClickText: '',
  animAlign: 'left',
  secondBtnAs: 'Link',
}

export default React.memo(ButtonSuccessfulSt)
