import { em } from 'polished'
import styled from 'styled-components'

import { unstyleButton } from 'styles/mixins'

const arrowWidthBase = 40
const arrowWidthActive = 60
const arrowWidthActiveEm = em(arrowWidthActive)
const delta = em(arrowWidthActive - arrowWidthBase)

const Wrapper = styled.div`
  display: inline-block;
  overflow: hidden;
`

export const WrapperLeft = styled(Wrapper)`
  padding-left: calc(${delta} + 1px);
`

export const WrapperRight = styled(Wrapper)`
  padding-right: calc(${delta} + 1px);
`

const Arrow = styled.div`
  width: ${arrowWidthActiveEm};
  height: ${p => p.theme.arrows.arrowIconHBase};
  transition: ${p =>
    `transform ${p.theme.animation.timeBtns} ${p.theme.animation.cubicBezier}`};
`

export const ArrowLeft = styled(Arrow)`
  margin-right: -${delta};
`

export const ArrowRight = styled(Arrow)`
  margin-left: -${delta};
`

export const Button = styled.button`
  display: inline-block;
  cursor: pointer;
  ${unstyleButton};

  ${p => p.theme.mq.hover} {
    &:hover {
      ${ArrowLeft} {
        transform: translateX(-${delta});
      }
      ${ArrowRight} {
        transform: translateX(${delta});
      }
    }
  }
`
