import PropTypes from 'prop-types'
import React from 'react'

import { MenuMainContext } from 'contexts/MenuMain'

import { Wrapper, BoxBaseStyled } from './styles'

const FirstBlockWrapper = ({ className, children, beforeElement }) => {
  const { isSalesBanner, salesBannerHeight } = React.useContext(MenuMainContext)

  return (
    <Wrapper
      className={className}
      withExtraIndent={isSalesBanner}
      indentHeight={salesBannerHeight}
    >
      {beforeElement}
      <BoxBaseStyled>{children}</BoxBaseStyled>
    </Wrapper>
  )
}

FirstBlockWrapper.propTypes = {
  /** You can pass here an element to render it before section
   * (for example: to fill whole area with image or some media)*/
  beforeElement: PropTypes.element,

  /** Inner section content */
  children: PropTypes.node,

  /** Classes for inner div element */
  className: PropTypes.string,

  /** Extra top indent by menu's second level for content */
  isExtraTopIndent: PropTypes.bool,

  indentHeight: PropTypes.shape({
    xs: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
  }),
}

FirstBlockWrapper.defaultProps = {
  className: '',
  beforeElement: null,
  isExtraTopIndent: false,
}

export default FirstBlockWrapper
