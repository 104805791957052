import PropTypes from 'prop-types'
import React from 'react'

import SliderClassicCounter from './components/SliderClassicCounter'
import SliderTextCounter from './components/SliderTextCounter'
import {
  ButtonArrowLongLeft,
  ButtonArrowLongRight,
  Container,
  Counter,
  Wrapper,
} from './styles'

/**
 * Component of slider control with long arrows
 * @param {object} props - component's props
 * @return {Component} Component — ControlSliderArrows
 */
const ControlSliderArrows = props => {
  const {
    className,
    isInfinity,
    isWide,
    isLightColor,
    activeIndex,
    amount,
    handleLeft,
    handleRight,
    descriptions,
    isWithLoader,
    loaderDuration,
    isLoaderActive,
  } = props

  return (
    <Container className={`${className}`}>
      <Wrapper isWide={isWide}>
        <ButtonArrowLongLeft
          isBlocked={!isInfinity && activeIndex === 1}
          direction="left"
          isWhite={isLightColor}
          onClick={handleLeft}
        />
        <Counter isWhite={isLightColor}>
          {descriptions && descriptions.length === amount ? (
            <SliderTextCounter
              descriptions={descriptions}
              curIndex={activeIndex}
            />
          ) : (
            <SliderClassicCounter amount={amount} curIndex={activeIndex} />
          )}
        </Counter>
        <ButtonArrowLongRight
          isWhite={isLightColor}
          isBlocked={!isInfinity && activeIndex === amount}
          onClick={handleRight}
          isWithLoader={isWithLoader}
          loaderDuration={loaderDuration}
          activeIndex={activeIndex}
          isLoaderActive={isLoaderActive}
        />
      </Wrapper>
    </Container>
  )
}

ControlSliderArrows.propTypes = {
  /** Active slide's index */
  activeIndex: PropTypes.number.isRequired,

  /** Amount of slides */
  amount: PropTypes.number.isRequired,

  /** Custom class name for control's container */
  className: PropTypes.string,

  /** Array of descriptions to show it instead of number counter */
  descriptions: PropTypes.array,

  /** Callback for the left arrow click */
  handleLeft: PropTypes.func,

  /** Callback for the right arrow click */
  handleRight: PropTypes.func,

  /** Flag to set up scroll is infinity or not */
  isInfinity: PropTypes.bool,

  /** Flag to set up light theme colors */
  isLightColor: PropTypes.bool,

  /** Flag to set up wide design or narrow */
  isWide: PropTypes.bool,

  /** Flag to set loader */
  isWithLoader: PropTypes.bool,

  /** Loader duration time */
  loaderDuration: PropTypes.number,

  /** Flag to control loader animation */
  isLoaderActive: PropTypes.bool,
}

ControlSliderArrows.defaultProps = {
  className: '',
  isInfinity: false,
  isWide: true,
}

export default ControlSliderArrows
