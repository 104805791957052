import { SALES_RUS, SALES_UK, SALES_EU, SALES_CA } from 'constants/sales'
import Sentry from 'utils/sentry'

export default function(intlCode, { currency, style }) {
  let symbol = ''
  let isInverted = true

  if (style !== 'currency') {
    Sentry.captureMessage('Intl fallback work only for currency')
  }

  switch (currency) {
    case SALES_RUS:
      isInverted = false
      symbol = '₽'
      break
    case SALES_UK:
      symbol = '£'
      break
    case SALES_EU:
      symbol = '€'
      break
    case SALES_CA:
      symbol = '$'
      break
    case 'USD':
      symbol = '$'
      break
    default:
      Sentry.captureMessage(`There is no currency symbol for ${currency} code`)
      symbol = currency
  }

  return {
    format: value => (isInverted ? `${symbol} ${value}` : `${value} ${symbol}`),
  }
}
