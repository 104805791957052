import { em } from 'polished'
import styled, { css } from 'styled-components'

import ButtonArrowLong from 'components/ButtonArrowLong'

export const Container = styled.div`
  text-align: center;
`

const ButtonArrowLongStyled = styled(ButtonArrowLong)`
  display: inline-block;
  cursor: pointer;
  user-select: none;
  transition: ${({
    theme: {
      animation: { timeStandard, cubicBezier },
    },
  }) => `opacity ${timeStandard} ${cubicBezier}`};
  -webkit-backface-visibility: hidden;

  ${({ isBlocked, theme }) =>
    isBlocked &&
    css`
      opacity: ${theme.blocks.blockMutedOpacity};
      pointer-events: none;
    `}
`

export const ButtonArrowLongLeft = styled(ButtonArrowLongStyled)``

export const ButtonArrowLongRight = styled(ButtonArrowLongStyled)``

export const Counter = styled.div`
  text-align: center;
  user-select: none;
  padding-left: ${em(20)};
  padding-right: ${em(20)};

  ${({ isWhite }) => isWhite && `color: white`}
`

export const Wrapper = styled.div`
  ${({ isWide }) =>
    isWide
      ? css`
          width: 100%;
          display: flex;

          ${Counter} {
            flex-grow: 1;
          }

          ${ButtonArrowLongLeft} {
            margin-left: -${em(20)};
          }

          ${ButtonArrowLongRight} {
            margin-right: -${em(20)};
          }
        `
      : css`
          display: inline-block;

          ${Counter} {
            display: inline-block;
          }
        `}
`
