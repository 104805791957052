import { isInteger, round } from 'lodash'
import PropTypes from 'prop-types'
import { Component } from 'react'

const STEPS = 40

/**
 * NumberAnimator component to animate increasing and decreasing numbers (float or integer)
 * @return {Component} Component — NumberAnimator
 */
export default class NumberAnimator extends Component {
  /**
   * getPrecision function
   * @params {{
   *   value: number,
   *   precision: number,
   * }} parameters
   * @return {number} precision of number
   */
  static getPrecision = ({ value, precision }) => {
    if (
      typeof precision !== 'undefined' &&
      precision !== null &&
      isInteger(precision)
    ) {
      return precision
    }

    if (isInteger(value)) {
      return 0
    }
    const precisionCalculated = `${value}`.split('.')[1].length

    return precisionCalculated > 2 ? 2 : precisionCalculated
  }

  static propTypes = {
    /** Number. Change this number to start animation */
    value: PropTypes.number.isRequired,

    /** Custom precision for number */
    precision: PropTypes.number,
  }

  constructor(props) {
    super(props)
    const { value, precision } = props

    this.animationId = null
    this.progress = 0

    this.state = {
      animateToVal: value,
      curValue: value,
      delta: 0,
      precision: NumberAnimator.getPrecision({ value, precision }),
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { animateToVal, curValue } = prevState
    const { value, precision } = nextProps

    if (value !== animateToVal) {
      return {
        animateToVal: value,
        delta: (value - curValue) / STEPS,
        precision: NumberAnimator.getPrecision({ value, precision }),
      }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState) {
    const { animateToVal } = this.state

    if (animateToVal !== prevState.animateToVal) {
      this.animation()
    }
  }

  componentWillUnmount() {
    if (this.animationId) {
      cancelAnimationFrame(this.animationId)
      this.animationId = null
    }
  }

  step = () => {
    const { animateToVal, delta, precision, curValue } = this.state
    const nextValue = round(curValue + delta, precision)
    const isNextValueOvertakedAim =
      delta < 0 ? animateToVal - nextValue < 0 : animateToVal - nextValue > 0

    if (!this.animationId) {
      return
    }

    this.progress++
    this.setState({
      curValue: nextValue,
    })

    if (this.progress < STEPS && isNextValueOvertakedAim && this.animationId) {
      requestAnimationFrame(this.step)
    } else {
      this.setState({
        curValue: animateToVal,
      })
      this.animationId = null
    }
  }

  animation = () => {
    if (this.animationId) {
      cancelAnimationFrame(this.animationId)
      this.animationId = null
    }

    this.progress = 0
    this.animationId = requestAnimationFrame(this.step)
  }

  render() {
    const { curValue } = this.state

    return curValue
  }
}
