import PropTypes from 'prop-types'
import React from 'react'

import NumberAnimator from 'components/NumberAnimator'
import { CA, COUNTRIES } from 'constants/countries'
import {
  Text,
  PriceBig,
  H2Like,
  PriceMedium,
  TextMedium,
  Bold,
  PriceSmall,
  PriceBaseBold,
  CartBig,
  CartItemTitle,
  CartEmptyPrice,
} from 'styles/components/typography'
import customFormatter from 'utils/customFormatter'

import { CostWrapper } from './styles'

export const SIZE_MAP = {
  xs: CartItemTitle,
  small: PriceSmall,
  base: Text,
  baseBold: Bold,
  cart: TextMedium,
  cartEmptyPrice: CartEmptyPrice,
  cartBig: CartBig,
  h2: H2Like,
  baseMedium: PriceBaseBold,
  medium: PriceMedium,
  big: PriceBig,
}

const { intlCode: CANADA_INTL_CODE } = COUNTRIES[CA]

/**
 * This component extends NumberAnimator
 *
 * Cost component to show cost with right localization
 *
 * @return {NumberAnimator} Component — Cost
 */
class Cost extends NumberAnimator {
  static numberFormat(intlCode, opts) {
    if (process.browser && !('Intl' in window)) {
      // For browsers without Intl
      return customFormatter(intlCode, opts)
    }

    return new Intl.NumberFormat(intlCode, opts)
  }

  constructor(props) {
    super(props)
    const { currency, intlCode } = props

    this.state.currency = currency
    this.state.intlCode = intlCode
    this.state.customPrefix = intlCode === CANADA_INTL_CODE ? 'C' : null
    this.state.formatter = Cost.numberFormat(intlCode, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: this.state.precision,
      maximumFractionDigits: this.state.precision,
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const result =
      NumberAnimator.getDerivedStateFromProps(nextProps, prevState) || {}
    const { intlCode, currency } = nextProps
    const precision = result.precision || prevState.precision
    const intlCodeChanged = intlCode !== prevState.intlCode
    const precisionChanged = precision !== prevState.precision
    const currencyChanged = currency !== prevState.currency

    if (intlCodeChanged || precisionChanged || currencyChanged) {
      return {
        ...result,
        intlCode,
        currency,
        customPrefix: intlCode === CANADA_INTL_CODE ? 'C' : null,
        formatter: Cost.numberFormat(intlCode, {
          style: 'currency',
          currency,
          minimumFractionDigits: precision,
          maximumFractionDigits: precision,
        }),
      }
    } else if ('precision' in result) {
      return result
    }

    return null
  }

  render() {
    const { size, className, strikethrough, isWhite } = this.props
    const { curValue, formatter, customPrefix } = this.state
    let Component = SIZE_MAP[size]

    return (
      <CostWrapper
        key={curValue}
        {...{ className, isWhite, size, isStrikethrough: strikethrough }}
      >
        <Component as="div">
          <>
            {customPrefix}
            {formatter.format(curValue)}
          </>
        </Component>
      </CostWrapper>
    )
  }
}

Cost.propTypes = {
  /** Code of needed currency */
  currency: PropTypes.string.isRequired,

  /** Intl code to format cost */
  intlCode: PropTypes.string.isRequired,

  /** Cost that need to show */
  value: PropTypes.number.isRequired,

  /** Class name of component */
  className: PropTypes.string,

  /** Flag to define color theme */
  isWhite: PropTypes.bool,

  /** Custom precision for cost */
  precision: PropTypes.number,

  /** Size of font for cost rendering */
  size: PropTypes.oneOf(Object.keys(SIZE_MAP)),

  /** Decorate text with line-through */
  strikethrough: PropTypes.bool,
}

Cost.defaultProps = {
  size: 'h2',
  className: '',
  strikethrough: false,
  precision: null,
  isWhite: false,
}

export default Cost
