import color from '@styled-system/color'
import { compose } from '@styled-system/core'
import flexbox from '@styled-system/flexbox'
import layout from '@styled-system/layout'
import position from '@styled-system/position'
import { em, math } from 'polished'
import styled from 'styled-components'

import {
  getRowConfig,
  makeXs0,
  makeXs20,
  makeMd0,
  makeMd20,
  makeMd40,
  makeLg0,
  makeLg40,
  makeLg20,
} from 'styles/mixins_styled/row'

const Box = styled.div(compose(layout, position, color, flexbox))

export const BoxBase = styled(Box)`
  padding-left: ${p => p.theme.indents.baseIndentXs};
  padding-right: ${p => p.theme.indents.baseIndentXs};

  ${p => p.theme.mq.upMd} {
    padding-left: ${p => p.theme.indents.baseIndentMd};
    padding-right: ${p => p.theme.indents.baseIndentMd};
  }
  ${p => p.theme.mq.upLg} {
    padding-left: ${p => p.theme.indents.baseBoxIndentLg};
    padding-right: ${p => p.theme.indents.baseBoxIndentLg};
  }
`

const rowSystem = styled.div(compose(color, layout, flexbox))

const row = styled(rowSystem)`
  display: flex;
  flex-wrap: ${p => (p.flexDirection === 'column' ? 'nowrap' : 'wrap')};
`

export const Row = styled(row)`
  margin-left: -${p => math(`${p.theme.indents.baseIndentXs}/2`)};
  margin-right: -${p => math(`${p.theme.indents.baseIndentXs}/2`)};

  ${p => p.theme.mq.upMd} {
    margin-left: -${p => math(`${p.theme.indents.baseIndentMd}/2`)};
    margin-right: -${p => math(`${p.theme.indents.baseIndentMd}/2`)};
  }
`

/*
 * indent: {
 *  xs: "0|20",
 *  md: "0|20|40",
 *  lg: "0|20|40",
 * }
 * default: { xs: 20, md: 40, lg: 40}
 */
export const RowIndented = styled(row)`
  ${p => getRowConfig(p).xs === 0 && makeXs0};
  ${p => getRowConfig(p).xs === 20 && makeXs20};

  ${p => getRowConfig(p).md === 0 && makeMd0};
  ${p => getRowConfig(p).md === 20 && makeMd20};
  ${p => getRowConfig(p).md === 40 && makeMd40};

  ${p => getRowConfig(p).lg === 0 && makeLg0}
  ${p => getRowConfig(p).lg === 20 && makeLg20};
  ${p => getRowConfig(p).lg === 40 && makeLg40};
`

export const Col = styled(rowSystem)`
  padding-left: ${p => math(`${p.theme.indents.baseIndentXs}/2`)};
  padding-right: ${p => math(`${p.theme.indents.baseIndentXs}/2`)};

  ${p => p.theme.mq.upMd} {
    padding-left: ${p => math(`${p.theme.indents.baseIndentMd}/2`)};
    padding-right: ${p => math(`${p.theme.indents.baseIndentMd}/2`)};
  }
`

export const LastBlock = styled.div`
  padding-bottom: ${em(40)};
  padding-top: ${em(20)};

  ${p => p.theme.mq.upMd} {
    padding-bottom: ${em(60)};
    padding-top: ${em(60)};
  }
`
