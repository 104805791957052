import NextLink from 'next/link'
import PropTypes from 'prop-types'
import React from 'react'

import htmlDecode from 'utils/htmlDecode'

function Link({
  to,
  asPath,
  children,
  innerRef,
  className,
  onClick,
  isTargetBlank,
  component,
  label,
}) {
  const target = isTargetBlank ? '_blank' : '_self'
  const rel = isTargetBlank ? 'noopener noreferrer' : ''
  const additionalProps = React.useMemo(() => {
    return component === 'a' ? { href: asPath } : {}
  }, [component, asPath])
  const labelDecoded = React.useMemo(() => htmlDecode(label), [label])
  const content = React.useMemo(
    () => (
      <a
        {...{ rel, target, className, onClick }}
        {...additionalProps}
        ref={innerRef}
        aria-label={labelDecoded}
      >
        {children}
      </a>
    ),
    [
      rel,
      target,
      className,
      onClick,
      innerRef,
      children,
      additionalProps,
      labelDecoded,
    ],
  )

  if (component === 'a') {
    return content
  }

  return (
    <NextLink as={asPath} href={to}>
      {content}
    </NextLink>
  )
}

Link.propTypes = {
  component: PropTypes.oneOf(['Link', 'a']),
  to: PropTypes.string.isRequired,
  asPath: PropTypes.string,
  children: PropTypes.node,
  innerRef: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  isTargetBlank: PropTypes.bool,
}

Link.defaultProps = {
  component: 'Link',
  label: '',
  isTargetBlank: false,
}

export default Link
