import { round } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { addAmount, sendPromo } from 'actions'
import { ResizeContext } from 'contexts/Resize'
import { cart } from 'routes'
import { getIsCartInitializingOrUpdating } from 'selectors'
import getDistributorLink from 'utils/sales/getDistributorLink'

export default ({ isSubscription, productType }) => {
  const { mobile } = React.useContext(ResizeContext)
  const dispatch = useDispatch()
  const isCartInProgress = useSelector(getIsCartInitializingOrUpdating)
  const loc = useSelector(state => state.localization)
  const {
    pages: { common },
    locale,
    sales: {
      currency,
      isFetching,
      goods,
      specialDistribution,
      withoutSales,
      isGroupon,
    },
    paths: { cdn_global },
    currentCountry: { intlCode },
  } = loc
  const [isActive, setActivity] = React.useState(true)
  const [amount, setAmount] = React.useState(1)
  const [costs, setCosts] = React.useState({
    cost: 0,
    realCost: 0,
  })

  const calculateCost = React.useCallback(
    amount => {
      const { [productType]: { price = 0, priceWebsite = 0 } = {} } =
        goods || {}

      return {
        cost: round(amount * priceWebsite, 2),
        realCost: round(amount * price, 2),
      }
    },
    [productType, goods],
  )

  React.useEffect(() => {
    const { cost, realCost } = calculateCost(amount)
    setCosts({ cost, realCost })
  }, [isCartInProgress, calculateCost, amount, goods, productType])
  React.useEffect(() => {
    setActivity(!isFetching)
  }, [isFetching])

  const handleDecrease = React.useCallback(() => {
    if (amount > 1 && isActive) {
      const nextAmount = amount - 1
      const { cost, realCost } = calculateCost(nextAmount)

      setCosts({ cost, realCost })
      setAmount(nextAmount)
    }
  }, [calculateCost, amount, isActive])
  const handleIncrease = React.useCallback(() => {
    if (isActive) {
      const nextAmount = amount + 1
      const { cost, realCost } = calculateCost(nextAmount)

      setCosts({ cost, realCost })
      setAmount(nextAmount)
    }
  }, [calculateCost, amount, isActive])
  const handleAddToCartBegin = React.useCallback(() => {
    setActivity(false)
  }, [])
  const handleAddToCartEnd = React.useCallback(() => {
    dispatch(
      addAmount({
        productType,
        amount,
        isSubscription,
      }),
    )
  }, [dispatch, productType, isSubscription, amount])

  // reset Groupon promo after adding extra tests to cart
  const handleSalesResetAfterAddToCartRedirect = React.useCallback(() => {
    if (isGroupon)
      dispatch(
        sendPromo({
          promocode: undefined,
          locale,
        }),
      )
  }, [dispatch, locale, isGroupon])

  const result = {
    commonUpsaleProps: {
      boxesPath: `${cdn_global}/boxes`,
      locale,
      isCartInProgress,
      isFetching,
      isMobile: mobile,
      withoutSales,
    },
    dict: common,
    locale,
  }

  if (!specialDistribution) {
    result.commonUpsaleProps = {
      ...result.commonUpsaleProps,
      counterHandleDecrease: handleDecrease,
      counterHandleIncrease: handleIncrease,
      counterAmountIsActive: isActive,
      counterDescription: common.kits.amount,
      counterAmount: amount,
      buttonHandleClickBegin: handleAddToCartBegin,
      buttonHandleClickEnd: handleAddToCartEnd,
      buttonLinkTitle: withoutSales
        ? common.buttons.out_of_stock
        : common.buttons.go_to_cart,
      buttonLink: cart.link(locale),
      buttonAsPath: cart.as(locale),
      buttonTitle: common.buttons.add_to_cart,
      buttonLinkClick: handleSalesResetAfterAddToCartRedirect,
      isDisabled: withoutSales,
      costLocale: intlCode,
      costCurrency: currency,
      costValue: costs.cost,
      costRealValue: costs.realCost,
    }
  } else {
    result.commonUpsaleProps = {
      ...result.commonUpsaleProps,
      buttonLinkTitle: common.buttons.go_to_cart,
      buttonLink: getDistributorLink(productType, locale),
      buttonLinkAs: 'a',
      isDisabled: false,
      buttonTitle: common.buttons.add_to_cart,
    }
  }

  return result
}
