import { forEach } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getPage as getPageDefault } from 'actions'

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default function(LocalizedComponent, mainName, extraNames = {}) {
  const names = { ...extraNames, texts: mainName }

  class LocalizationPage extends Component {
    static propTypes = {
      getPageDefault: PropTypes.func.isRequired,
      language: PropTypes.string.isRequired,
      locale: PropTypes.string.isRequired,
      pages: PropTypes.object.isRequired,
      pagesFetching: PropTypes.object.isRequired,
      paths: PropTypes.object.isRequired,
      cdnPaths: PropTypes.object.isRequired,
    }

    static async getInitialProps({ ctx }) {
      if (!process.browser) {
        forEach(names, name =>
          ctx.store.dispatch(getPageDefault({ page: name })),
        )
      }

      return {}
    }

    componentDidMount() {
      const { getPageDefault, pages, pagesFetching } = this.props

      forEach(names, name => {
        if (!pages[name] && !pagesFetching[name]) {
          getPageDefault({ page: name })
        }
      })
    }

    componentDidUpdate(prevProps) {
      const { getPageDefault, language, pagesFetching } = this.props

      if (language !== prevProps.language) {
        forEach(names, name => {
          if (!pagesFetching[name]) {
            getPageDefault({ page: name })
          }
        })
      }
    }

    render() {
      const {
        pages,
        pagesFetching,
        locale,
        language,
        paths,
        cdnPaths,
        // Disable because we do not use that var
        // but we need to filter it to not pass through
        // eslint-disable-next-line no-unused-vars
        getPageDefault,
        ...propsThatWePassThrough
      } = this.props
      const dataProps = {
        isFetching: pagesFetching[mainName],
      }

      forEach(names, (name, key) => {
        dataProps[key] = pages[name]
      })

      if (!pages[mainName]) {
        return null
      }

      return (
        <LocalizedComponent
          staticPath={paths[mainName]}
          pathCdn={cdnPaths[mainName]}
          pathGlobal={paths.cdn_global}
          pathShared={paths.shared}
          language={language}
          locale={locale}
          {...dataProps}
          {...propsThatWePassThrough}
        />
      )
    }
  }

  LocalizationPage.displayName = `withLocalization(${getDisplayName(
    LocalizedComponent,
  )})`

  function mapStateToProps(state) {
    return {
      language: state.localization.language,
      locale: state.localization.locale,
      paths: state.localization.paths,
      cdnPaths: state.localization.cdnPaths,
      pages: state.localization.pages,
      pagesFetching: state.localization.pagesFetching,
    }
  }

  const mapDispatchToProps = {
    getPageDefault,
  }

  return connect(mapStateToProps, mapDispatchToProps)(LocalizationPage)
}
