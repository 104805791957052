import PropTypes from 'prop-types'
import React, { useContext, useCallback } from 'react'

import Icon from 'components/Icon'
import { MenuMainContext } from 'contexts/MenuMain'
import { TextLH } from 'styles/components/typography'
import { scrollToBlock } from 'utils/client/scroll'

import { Wrapper, Button, TextBox, Arrow } from './styles'

/**
 * ButtonScrollDown — component to render button to scroll to some ref
 *
 * @param {object} props - properties of component
 * @returns {node} ButtonScrollDown
 */
const ButtonScrollDown = props => {
  const { title, isLightColor, refToScroll } = props
  const iconColor = isLightColor ? 'white' : 'black'
  const { menuNode } = useContext(MenuMainContext)
  const handleScroll = useCallback(() => {
    scrollToBlock(refToScroll, menuNode)
  }, [refToScroll, menuNode])

  return (
    <Wrapper>
      <Button
        className="link--inverted"
        onTouchEnd={handleScroll}
        onClick={handleScroll}
      >
        <TextBox isLight={isLightColor}>
          <TextLH dangerouslySetInnerHTML={{ __html: title }} />
        </TextBox>
        <Arrow>
          <Icon id="arrow_down" color={iconColor} />
        </Arrow>
      </Button>
    </Wrapper>
  )
}

ButtonScrollDown.propTypes = {
  /** Title of button */
  title: PropTypes.string.isRequired,

  /** Flag to invert color to light */
  isLightColor: PropTypes.bool,

  /** Ref to a node which we will use to scroll for on click or on tap */
  refToScroll: PropTypes.object,
}

ButtonScrollDown.defaultProps = {
  isLightColor: false,
}

export default ButtonScrollDown
