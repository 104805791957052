import CSSPlugin from 'gsapAlias/CSSPlugin'
import { Power0 } from 'gsapAlias/EasePack'
import TweenLite from 'gsapAlias/TweenLite'

// eslint-disable-next-line no-unused-vars
const plugins = [CSSPlugin]

const REVERSE_DURATION = 0.3 // 300ms for reverse time

export const loaderAnimation = ({ node, time }) =>
  TweenLite.to(node, time / 1000 - REVERSE_DURATION, {
    strokeDashoffset: 80,
    ease: Power0.easeNone,
    onComplete: () =>
      TweenLite.to(node, REVERSE_DURATION, {
        strokeDashoffset: 160,
        ease: Power0.easeNone,
      }),
  })
